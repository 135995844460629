import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  apiDeleteDocumentTemplate,
  apiGetDocumentTemplates,
} from "../../services/documentTemplatesAPI";
import {
  DocumentRecord,
  DocumentTemplate,
  DocumentTemplateImagePreview,
  DocumentTemplateSummary,
  Favourite,
} from "../../types/DocumentTemplate";
import DocumentTemplateInfoForm from "./DocumentTemplateInfoForm";

export interface LibraryState {
  documentTemplates: DocumentTemplate[];
  documentUniqueName: string | undefined;
  groupByTemplates: any | undefined;
  favourites: Favourite[];

  documentRecords: DocumentRecord[];
  status: "idle" | "loading" | "failed";
  error: string | undefined;
  preview: string | undefined;
  searchRelevance: any[];
  filterString: string;
  previewOpen?: boolean;
  viewRecents: boolean;
  viewFavourites: boolean;
  viewDraft: boolean;
  viewTemplates: boolean;
  viewPublished: boolean;
  viewOnceOffDocumnet: boolean;
  viewDocumentRecords: boolean;
  viewDeleted: boolean;
  viewAuthoredByMe: boolean;
  viewAwaitingApprovals: boolean;
  creditCount: number | undefined;

  sort: string;
  groupBy: string;
  documentTemplatesStatus: "idle" | "loading" | "failed";
}

const initialState: LibraryState = {
  documentTemplates: [] as DocumentTemplate[],
  favourites: [] as Favourite[],
  documentRecords: [] as DocumentRecord[],
  documentUniqueName: undefined,
  creditCount: undefined,
  status: "idle",
  error: undefined,
  preview: undefined,
  previewOpen: false,

  // For Filtering IN Library Menu
  filterString: "",
  searchRelevance: [],
  viewDeleted: false,
  viewPublished: false,
  viewDraft: false,
  viewDocumentRecords: false,
  viewRecents: false,
  viewFavourites: false,
  viewTemplates: false,
  viewOnceOffDocumnet: false,
  viewAuthoredByMe: false,
  viewAwaitingApprovals: false,
  sort: "Alphabetical",
  groupBy: "none",
  groupByTemplates: undefined,

  documentTemplatesStatus: "idle",
};

export const deleteDocumentTemplate = createAsyncThunk<
  { document_templates: DocumentTemplate[] },
  {
    organizationId: number;
    workspaceId: number;
    documentTemplate: DocumentTemplate;
  }
>("library/deleteDocumentTemplate", async (params) => {
  const response = await apiDeleteDocumentTemplate(
    params.organizationId,
    params.workspaceId,
    params.documentTemplate
  );
  const fetchLatestResponse = await apiGetDocumentTemplates(
    params.organizationId,
    params.workspaceId
  );
  // The value we return becomes the `fulfilled` action payload
  return fetchLatestResponse.data;
});

export const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<{
        status: "idle" | "loading" | "failed";
        error: string | undefined;
      }>
    ) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },

    setDocumentTemplatesStatus: (
      state,
      action: PayloadAction<{
        documentTemplatesStatus: "idle" | "loading" | "failed";
      }>
    ) => {
      state.documentTemplatesStatus = action.payload.documentTemplatesStatus;
    },
    setClearLibraryDocuments: (
      state,
  
    ) => {
      state.documentTemplates=[]
      state.documentRecords=[]
    },

    setRemoveDocument: (
      state,
      action: PayloadAction<{
        documentID: number;
      }>
    ) => {
      if (state.groupBy !== 'none') {
        // Do nothing if groupBy is not 'none'
        return;
      }
      state.documentTemplates = state.documentTemplates.filter(
        (template) => template.id !== action.payload.documentID
      );
    },

    setDocumentTemplates: (
      state,
      action: PayloadAction<DocumentTemplate[]>
    ) => {
      state.documentTemplates = action.payload;
    },
    setFavourites: (state, action: PayloadAction<Favourite[]>) => {
      state.favourites = action.payload;
    },
    setDocumentRecords: (state, action: PayloadAction<DocumentRecord[]>) => {
      state.documentRecords = action.payload;
    },
    setPreview: (state, action: PayloadAction<string>) => {
      state.preview = action.payload;
    },
    setPreviewOpen: (state, action: PayloadAction<boolean>) => {
      state.previewOpen = action.payload;
    },

    clearPreview: (state) => {
      state.preview = undefined;
      state.previewOpen = false;
    },
    setDocumentUniqueName: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.documentUniqueName = action.payload;
    },
    setFilterString: (state, action: PayloadAction<string>) => {
      state.filterString = action.payload;

      if (action.payload == "") {
        // if search is empty set  Alphabetical as default
        state.sort = "Alphabetical";
      } else {
        // if user type something set relevance by default as sorting
        state.sort = "Relevance";
      }
    },

    clearFilters: (state) => {
      state.documentTemplates = [] as DocumentTemplate[];
      state.documentRecords = [] as DocumentRecord[];
      state.documentUniqueName = undefined;
      state.filterString = "";
      state.searchRelevance = [];
      state.viewDeleted = false;
      state.viewPublished = false;
      state.viewDraft = false;
      state.viewDocumentRecords = false;
      state.viewRecents = false;
      state.viewFavourites = false;
      state.viewTemplates = false;
      state.viewOnceOffDocumnet = false;
      state.viewAuthoredByMe = false;
      state.viewAwaitingApprovals = false;
      state.sort = "Alphabetical";
      state.groupBy = "none";
      state.groupByTemplates = undefined;
    },

    setViewDeleted: (state, action: PayloadAction<boolean>) => {
      state.viewDeleted = action.payload;
    },
    setCreditCount: (state, action: PayloadAction<number>) => {
      state.creditCount = action.payload;
    },
    updateCreditCount: (state, action: PayloadAction<number>) => {
      state.creditCount = action.payload;
    },
    setViewPublished: (state, action: PayloadAction<boolean>) => {
      state.viewPublished = action.payload;
    },
    setViewDraft: (state, action: PayloadAction<boolean>) => {
      state.viewDraft = action.payload;
    },
    setViewDocumentRecord: (state, action: PayloadAction<boolean>) => {
      state.viewDocumentRecords = action.payload;
    },

    setViewRecents: (state, action: PayloadAction<boolean>) => {
      state.viewRecents = action.payload;
    },
    setGroupByTempaltes: (state, action: PayloadAction<any>) => {
      state.groupByTemplates = action.payload;
    },

    setSearchRelevance: (state, action: PayloadAction<any>) => {
      state.searchRelevance = action.payload;
    },

    setViewFavourites: (state, action: PayloadAction<boolean>) => {
      state.viewFavourites = action.payload;
    },

    setViewTemplates: (state, action: PayloadAction<boolean>) => {
      state.viewTemplates = action.payload;
    },

    setViewOnceOffDocuments: (state, action: PayloadAction<boolean>) => {
      state.viewOnceOffDocumnet = action.payload;
    },

    setViewAuthoredByMe: (state, action: PayloadAction<boolean>) => {
      state.viewAuthoredByMe = action.payload;
    },

    setViewAwaitingApproval: (state, action: PayloadAction<boolean>) => {
      state.viewAwaitingApprovals = action.payload;
    },

    setViewDocumentRecords: (state, action: PayloadAction<boolean>) => {
      state.viewDocumentRecords = action.payload;
    },

    setSortOrder: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },

    setGroupBy: (state, action: PayloadAction<string>) => {
      state.groupBy = action.payload;
    },

    updateDocumentTemplateLocal: (
      state,
      action: PayloadAction<DocumentTemplate>
    ) => {
      const index = state.documentTemplates.findIndex(
        (dt) => dt.id == action.payload.id
      );

      if (index >= 0) {
        state.documentTemplates.splice(index, 1, action.payload);
      }
      // let newDocumentTemplates = [...state.documentTemplates];
      if (state.groupBy != "none") {
        state.groupByTemplates &&
          Object.keys(state.groupByTemplates).map((key, index) => {
            const groupbyTemplateIndex = state.groupByTemplates[key].findIndex(
              (dt: any) => dt.id == action.payload.id
            );
            if (groupbyTemplateIndex >= 0) {
              // let newDocumentTemplates = [...state.documentTemplates];
              state.groupByTemplates[key].splice(
                groupbyTemplateIndex,
                1,
                action.payload
              );
            }
          });
      }
    },
    setImagePreview: (
      state,
      action: PayloadAction<{
        documentTemplate: DocumentTemplate;
        imagePreview: DocumentTemplateImagePreview;
      }>
    ) => {
      // Get the original document template.
      const index = state.documentTemplates.findIndex(
        (dt) => dt.id === action.payload.documentTemplate.id
      );
      if (index !== -1)
        state.documentTemplates[index].imagePreview =
          action.payload.imagePreview;
      else {
        //
        // console.error(
        //   `Cannot find DocumentTemplate with ID ${action.payload.documentTemplate.id}`
        // );
      }
    },

    setRecordImagePreview: (
      state,
      action: PayloadAction<{
        documentRecord: DocumentRecord;
        imagePreview: DocumentTemplateImagePreview;
      }>
    ) => {
      // Get the original document template.
      const index = state.documentRecords.findIndex(
        (dt) => dt.id === action.payload.documentRecord.id
      );
      if (index !== -1)
        state.documentRecords[index].imagePreview = action.payload.imagePreview;
      else {
        //
        // console.error(
        //   `Cannot find DocumentTemplate with ID ${action.payload.documentRecord.id}`
        // );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteDocumentTemplate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDocumentTemplate.fulfilled, (state, action) => {
        state.status = "idle";
        state.documentTemplates = action.payload.document_templates;
      })
      .addCase(deleteDocumentTemplate.rejected, (state, action) => {
        state.status = "idle";
        state.error = `${action.error?.code}: ${action.error?.message}`;
      });
  },
});

export const {
  setStatus,
  setError,
  setDocumentTemplatesStatus,
  setDocumentTemplates,
  setFavourites,
  setClearLibraryDocuments,
  setDocumentRecords,
  setDocumentUniqueName,
  setPreview,
  setPreviewOpen,
  clearFilters,
  clearPreview,
  setImagePreview,
  setFilterString,
  setRecordImagePreview,
  setSearchRelevance,
  setViewDeleted,
  setViewDraft,
  setViewPublished,
  setViewDocumentRecords,
  setViewRecents,
  setViewFavourites,
  setViewTemplates,
  setViewOnceOffDocuments,
  setViewAuthoredByMe,
  setViewAwaitingApproval,
  updateDocumentTemplateLocal,
  setSortOrder,
  setGroupBy,
  setGroupByTempaltes,
  setCreditCount,
  updateCreditCount,
  setRemoveDocument,
} = librarySlice.actions;

//Selectors
export const selectLibrary = (state: RootState) => state.library;
export const selectDocumentPreview = (state: RootState) =>
  state.library.preview;
export const selectTemplatePreviewStatus = (state: RootState) =>
  state.library.status;
export const selectFavourites = (state: RootState) => state.library.favourites;
export const selectDocumentTemplates = (state: RootState) =>
  state.library.documentTemplates;

export const selectIsFilterApplied = (state: RootState) => {
  return (
    state.library.viewRecents ||
    state.library.viewFavourites ||
    state.library.viewDraft ||
    state.library.viewTemplates ||
    state.library.viewPublished ||
    state.library.viewOnceOffDocumnet ||
    state.library.viewDeleted ||
    state.library.viewDocumentRecords ||
    state.library.viewAuthoredByMe ||
    state.library.viewAwaitingApprovals ||
    state.library.filterString != ""
  );
};

export const selectFilteredDocumentTemplatesAndRecords = (state: RootState) => {
  const list = [
    ...state.library.documentTemplates,
    ...state.documentRecords.documentRecords,
  ];
  // Sort the list.
  if (state.library.sort === "Alphabetical") {
    list.sort((a, b) => a.name.localeCompare(b.name));
  } else if (state.library.sort === "Date") {
    list.sort((a, b) => {
      if (a.created_at && b.created_at) {
        return b.created_at.localeCompare(a.created_at); // Compare in reverse order for descending
      } else if (!a.created_at && !b.created_at) {
        return 0; // Both objects are missing created_at, so their order doesn't matter
      } else if (!a.created_at) {
        return 1; // b comes before a since a doesn't have created_at
      } else {
        return -1; // a comes before b since b doesn't have created_at
      }
    });
  } else if (state.library.sort === "Relevance") {
    list.sort((a, b) => {
      const indexA = state.library.searchRelevance.indexOf(a.id);
      const indexB = state.library.searchRelevance.indexOf(b.id);
      if (indexA === -1) {
        // `a.id` not found in the sequence, move it to the end
        return 1;
      }
      if (indexB === -1) {
        // `b.id` not found in the sequence, move it to the end
        return -1;
      }
      // Compare the indices to maintain the sequence
      return indexA - indexB;
    });
  } else {
    return list;
  }
  return list;
};
export default librarySlice.reducer;
