import { json } from "react-router-dom";
import useActions, { useAppDispatch } from "../../../../app/hooks";
import {
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
} from "../../../../types/DocumentTemplate";
import { setSelectedComponent } from "../../editorSlice";

export const setElementLevelL1 = (
  section: DocumentSection,
  subSection: DocumentSubsection,
  element: DocumentElement
) => {
  try {
    const actions = useActions();
    const dispatch = useAppDispatch();
    if (section && subSection && element) {
      actions.setHeadingLevel({
        selected: {
          section: section,
          subSection: subSection,
          documentElement: element,
        },
        heading_level: 1 as number,
      });
      dispatch(
        setSelectedComponent({
          selectedSection: section as DocumentSection,
          selectedSubsection: subSection!,
          selectedComponent: {
            ...element,
            heading_level: 1 as number,
          } as DocumentElement,
        })
      );
    }
  } catch (error) {
    console.error("Unable to set Element Level to L0:", error);
  }
};

export function compareDeepObjects(object: any, documentState: any) {
  const propertiesToCompare = [
    "is_header",
    "header_selection_l",
    "header_selection_r",
    "header_free_text_l",
    "header_free_text_r",
    "header_free_text_c",
    "header_pageno_l",
    "header_pageno_r",
    "header_pageno_c",
    "page_number_style",
    "header_styles_l",
    "header_styles_r",
    "header_styles_c",
    "header_styles_l_color",
    "header_styles_r_color",
    "header_styles_c_color",
    "header_image_l",
    "header_image_r",
    "header_image_c",
    "header_font_size_l",
    "header_font_size_r",
    "header_font_size_c",
    "header_font_style_l",
    "header_font_style_r",
    "header_font_style_c",
    "footer_selection_l",
    "footer_selection_r",
    "footer_selection_c",
    "footer_free_text_l",
    "footer_free_text_r",
    "footer_free_text_c",
    "footer_pageno_l",
    "footer_pageno_r",
    "footer_pageno_c",
    "footer_styles_l",
    "footer_styles_r",
    "footer_styles_c",
    "footer_styles_l_color",
    "footer_styles_r_color",
    "footer_styles_c_color",
    "footer_image_l",
    "footer_image_r",
    "footer_image_c",
    "footer_font_size_l",
    "footer_font_size_r",
    "footer_font_size_c",
    "page_type",
    "page_height",
    "page_width",
    "page_margin_top",
    "page_margin_bottom",
    "orientation",
    "custom",
    "page_background_color",
    "page_columns",
    "page_margin_left",
    "page_margin_right",
    "header_line",
    "footer_line",
    "justification",
    "paragraph_font_size_pt",
    "paragraph_spacing_pt",
  ];

  // Properties to compare separately
  const separateComparisonProperties = [
    "heading1_formatting",
    "heading2_formatting",
    "heading3_formatting",
    "heading4_formatting",
    "heading5_formatting",
    "heading6_formatting",
    "paragraph1_formatting",
    "paragraph2_formatting",
    "paragraph3_formatting",
    "paragraph4_formatting",
    "paragraph5_formatting",
    "paragraph6_formatting",
  ];

  for (const property of propertiesToCompare) {
    if (object[property] !== documentState[property]) {
      return true;
    }
  }

  for (const property of separateComparisonProperties) {
    if (!deepEqual(object[property], documentState[property])) {
      return true;
    }
  }

  return false;
}

// {
//   "id": 1,
//   "title": "Heading 1",
//   "topMargin": "0",
//   "bottomMargin": "12",
//   "showColorPicker": false,
//   "fontColor": "000000",
//   "font": "arial",
//   "fontSize": "16",
//   "alignment": "raggedright",
//   "fontStyle": ["bold"],
//   "indent": 0,
//   "format": "arabic",
//   "prefix": "(",
//   "suffix": ")",
//   "compound": true,
//   "separator": "."
// }

function deepEqual(obj1: any, obj2: any) {
  const object1 = JSON.parse(obj1);
  const object2 = JSON.parse(obj2);

  // List the properties to compare
  const propertiesToCompare = [
    "topMargin",
    "bottomMargin",
    "font",
    "fontSize",
    "alignment",
    "indent",
    "format",
    "prefix",
    "suffix",
    "separator",
    "linespacing",
  ];

  // Iterate through the properties and return true if any are different
  for (const prop of propertiesToCompare) {
    if (object1[prop] !== object2[prop]) {
      // console.log("object1" , object1 , object2 , "object2" , object1[prop] ,"object2[prop]" , object2[prop] )

      return false;
    }
  }

  // Compare "fontStyle" as arrays
  if (!arraysAreEqual(object1.fontStyle, object2.fontStyle)) {
    return false;
  }
  // If none of the specified properties are different, return true
  return true;
}

function arraysAreEqual(arr1: any, arr2: any) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // If none of the specified properties are different, return false
  return true;
}

// function deepEqual(obj1: any, obj2: any): boolean {

//   const object1= JSON.parse(obj1)
//   const object2= JSON.parse(obj2)

// console.log("comparison",  object1== object2)

// {
//   "id": 1,
//   "title": "Heading 1",
//   "topMargin": "0",
//   "bottomMargin": "12",
//   "showColorPicker": false,
//   "fontColor": "000000",
//   "font": "arial",
//   "fontSize": "16",
//   "alignment": "raggedright",
//   "fontStyle": ["bold"],
//   "indent": 0,
//   "format": "arabic",
//   "prefix": "(",
//   "suffix": ")",
//   "compound": true,
//   "separator": "."
// }

// if(object1.title===object2.title && object1.topMargin===object2.topMargin &&  object1.bottomMargin===object2.bottomMargin ){
//   return false
// }

// return true

// }

// function deepEqual(obj1: any, obj2: any): boolean {

//    const object1= JSON.parse(obj1)
//    const object2= JSON.parse(obj2)

//    console.log("object1" , object1 , " object2" ,  object2)
//   if (obj1 === obj2) {
//     return true;
//   }

//   if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
//     return false;
//   }

//   const keys1 = Object.keys(obj1);
//   const keys2 = Object.keys(obj2);

//   if (keys1.length !== keys2.length) {
//     return false;
//   }

//   for (const key of keys1) {
//     if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
//       return false;
//     }
//   }

//   return true;
// }
