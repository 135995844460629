import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import useActions from "../../../app/hooks";

interface Organization_ {
  metric_limit: number;
  subscription_cycle: string;
  id: number;
  name: string;
}

export default function OrganizationDeleteForm(props: {
  organization: Organization_;
  closeDialogHandler: any;
}) {
  const actions = useActions();

  const handleClose = () => {
    props.closeDialogHandler();
  };
  const handleDeleteOrg = () => {
    if (props.organization) {
      actions.deleteOrganization({
        organizationID: props.organization.id,
      });
    }

    props.closeDialogHandler();
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle sx={{ fontWeight: "bold" }}>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" color="textPrimary">
            Are you sure you want to proceed with deleting this organization?
            This action is irreversible and cannot be undone.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleDeleteOrg}
          variant="contained"
          color="primary"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
