import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CheckBox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Variable } from "../../../../../types/DocumentTemplate";
import useActions from "../../../../../app/hooks";
import { useSelector } from "react-redux";
import { selectEditingDocumentTemplate } from "../../../editorSlice";
import Alert from "@mui/material/Alert";
import { FormControl, InputLabel } from "@mui/material";

const invalid_name = new RegExp("^[a-zA-Z0-9]+$");

export default function EditAddVariableForm(props: {
  handleClose: any;
  variable: Variable | null;
}) {
  const actions = useActions();
  const documentTemplate = useSelector(selectEditingDocumentTemplate);
  const variableCout = documentTemplate.variables?.length;
  const startElement = React.useMemo(() => {
    return JSON.parse(
      props.variable != null
        ? props.variable.content_format
        : '{"height":"3.8","width":"17","boarder":"F"}'
    );
  }, []);

  const [name, setName] = React.useState<string>(
    props.variable != null
      ? props.variable.variable_name
      : "Name" + (variableCout === 0 ? "" : variableCout)
  );

  const [height, setheight] = React.useState<string>(
    props.variable != null ? startElement.height : "3.8"
  );

  const [width, setwidth] = React.useState<string>(
    props.variable != null ? startElement.width : "17"
  );
  const [border, setBorder] = React.useState<string>(
    props.variable != null && startElement.border ? startElement.border : "F"
  );
  const listBorder = ["Full Border", "Underline"];
  const [value, setValue] = React.useState<string>(
    props.variable != null ? props.variable.default_value : ""
  );
  const [fillableField, setFillableField] = React.useState<boolean>(
    props.variable != null ? props.variable.fillable_field : false
  );

  const [forEsign, setForEsign] = React.useState<boolean>(
    props.variable != null ? startElement.sendForEsign : false
  );

  const [_fieldType, set_FieldType] = React.useState<string>(
    props.variable != null ? startElement.type ?? "TEXT" : "TEXT"
  );

  const [fieldType, setFieldType] = React.useState<string>(
    props.variable != null ? props.variable.field_type : "NAME"
  );

  const [signatory, setSignatory] = React.useState<any>(
    props.variable != null ? startElement.signatory ?? 1 : 1
  );

  const handleSignatoryChange = (event: any) => {
    setSignatory(event.target.value);
  };

  const signatoryList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [nameError, setNameError] = React.useState("");
  const isAdding = props.variable == null;

  const oldname =
    !isAdding && props.variable?.variable_name
      ? props.variable.variable_name
      : "null";

  const handleCancel = () => {
    props.handleClose();
  };

  const handleSave = () => {
    if (fillableField) {
      const newParms = JSON.stringify({
        height: height,
        width: width,
        border: border,
        sendForEsign: forEsign,
        type: _fieldType,
        signatory: signatory,
      });

      const cvar = {
        variable_name: name!,
        // default_value: value!,
        default_value: "",
        fillable_field: fillableField!,
        field_type: fieldType!,
        content_format: newParms!,
      };
      if (props.variable == null) {
        actions.createVariable(cvar);
      } else {
        const variable = Object.assign({}, props.variable, cvar);
        actions.updateVariable(variable);
      }
    } else {
      const newParms = JSON.stringify({});

      const cvar = {
        variable_name: name!,
        default_value: value!,
        fillable_field: fillableField!,
        field_type: fieldType!,
        content_format: newParms!,
      };
      if (props.variable == null) {
        actions.createVariable(cvar);
      } else {
        const variable = Object.assign({}, props.variable, cvar);
        actions.updateVariable(variable);
      }
    }
    props.handleClose();
  };

  const handleBorder = (e: any) => {
    setBorder(e.target.value);
  };

  const [errorHeight, setErrorHeight] = React.useState("");

  const handleVariableNameChange = (e: any) => {
    const newValue = e.target.value.trim();

    // Check for empty value or presence of special characters or spaces
    if (newValue === "") {
      setNameError("Write a variable name without special characters.");
    } else if (!invalid_name.test(newValue)) {
      setNameError(
        "Variable name can only contain letters and digits, without spaces or special characters."
      );
    } else {
      setNameError(""); // Clear error if the name is valid
    }

    setName(newValue);

    // Check if the name already exists in the document template
    if (
      oldname !== newValue &&
      documentTemplate.variables?.some(
        (variable) => variable.variable_name === newValue
      )
    ) {
      setNameError("This name already exists.");
    }
  };

  const handleDefaultValueChange = (e: any) => {
    setValue(e.target.value);
  };

  const handleHeightChange = (e: any) => {
    const inputValue = e.target.value;

    // Check if the input is a valid positive number or float
    // if (/^[+]?\d+(\.\d+)?$/.test(inputValue) || inputValue === "") {
    //   setheight(inputValue);
    // }
    if (/^[+]?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setheight(inputValue);
    }

    if (inputValue === 0) {
      setErrorHeight("Height cannot be zero");
    } else {
      setErrorHeight("");
    }
  };

  const handleWidthChange = (e: any) => {
    const inputValue = e.target.value;
    if (/^[+]?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setwidth(inputValue);
    }
  };

  const handleFieldTypeChange = (e: any) => {
    set_FieldType(e.target.value);
  };

  const handleFillableFieldChange = (e: any) => {
    if (e.target.checked) {
      setBorder("F");
    }
    setFillableField(e.target.checked);
  };

  return (
    <Dialog
      style={{ position: "absolute" }}
      open={true}
      onClose={props.handleClose}
    >
      <DialogTitle>
        {isAdding ? "Add" : "Edit"} variable or fillable field
      </DialogTitle>
      <DialogContent>
        <Box sx={{ margin: 0, paddingBottom: nameError ? "0px" : "22px" }}>
          <TextField
            autoFocus
            helperText={
              nameError && <span style={{ color: "red" }}>{nameError}</span>
            }
            margin="dense"
            id="name_var"
            label="Name"
            fullWidth
            variant="filled"
            placeholder="Provide Name"
            value={name}
            onChange={handleVariableNameChange}
          />
        </Box>
        <TextField
          margin="dense"
          disabled={fillableField}
          id="name"
          label="Default Value"
          fullWidth
          variant="outlined"
          placeholder="Provide a default value"
          value={value}
          onChange={handleDefaultValueChange}
        />
        <Box>
          <Typography
            sx={{ display: "inline", marginTop: "15px", marginBottom: "15px" }}
          >
            Is a fillable field
          </Typography>
          <CheckBox
            checked={fillableField}
            onChange={(event) => handleFillableFieldChange(event)}
          />
        </Box>
        <TextField
          margin="dense"
          id="name"
          label="Height *(mm)"
          fullWidth
          variant="filled"
          placeholder="Provide height"
          value={height}
          onChange={handleHeightChange}
          required
          disabled={!fillableField}
          error={height == "0"} // Set error prop to true if there is an error
          helperText={height == "0" ? "Height cannot be zero" : ""} // Display the error message as helper text
        />

        <TextField
          margin="dense"
          id="name"
          label="Width *(mm)  "
          fullWidth
          variant="filled"
          required
          placeholder="Provide Width"
          value={width}
          onChange={handleWidthChange}
          disabled={!fillableField}
          error={width == "0"} // Set error prop to true if there is an error
          helperText={width == "0" ? "Width cannot be zero" : ""} // Display the error message as helper text
        />

        <TextField
          sx={{ marginTop: 1 }}
          label="Border"
          variant={"filled"} // Adjust the variant based on the selected border
          defaultValue={border}
          onChange={handleBorder}
          fullWidth={true}
          select
          disabled={!fillableField}
        >
          <MenuItem key="Full Border" value="F">
            Full Border
          </MenuItem>
          {/* <MenuItem key="PUBLISHER" value="PUBLISHER">Publisher</MenuItem> */}
          <MenuItem key="Underline" value="U">
            Underline
          </MenuItem>
          <MenuItem key="No Border" value="N">
            No Border
          </MenuItem>
        </TextField>

        {fillableField && (
          <Box>
            <Box sx={{ py: 1 }}>
              <Typography
                sx={{
                  display: "inline",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                Will this fillable field be used for e-signing?
              </Typography>
              <CheckBox
                checked={forEsign}
                onChange={(event) => setForEsign(event.target.checked)}
              />
            </Box>

            {forEsign && (
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="field-type-select">
                    Field type
                  </InputLabel>
                  <Select
                    label="Field type"
                    value={_fieldType}
                    onChange={handleFieldTypeChange}
                    fullWidth={true}
                  >
                    {/* <MenuItem value="">
      None
    </MenuItem> */}
                    {/* <MenuItem key="DESIGNATION" value="DESIGNATION">
       Designation
       </MenuItem> */}
                    {/* <MenuItem key="INITIAL" value="INITIAL">
         Initial
       </MenuItem> */}
                    <MenuItem key="SIGNATURE" value="SIGNATURE">
                      Signature
                    </MenuItem>
                    <MenuItem key="DATE" value="DATE">
                      Date
                    </MenuItem>
                    <MenuItem key="TEXT" value="TEXT">
                      Text
                    </MenuItem>
                    {/* <MenuItem key="DESIGNATION" value="DESIGNATION">
       Designation
       </MenuItem> */}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel htmlFor="field-type-select">Signatory</InputLabel>

                  <Select
                    label="Signatory"
                    value={signatory}
                    fullWidth={true}
                    onChange={handleSignatoryChange} // Call the handler function when the selection changes
                  >
                    {signatoryList.map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={
            nameError !== "" ||
            (!(width != undefined && width.trim() != "") && fillableField) ||
            (!(height != undefined && height.trim() != "") && fillableField)
          }
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
