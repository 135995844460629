

// src/components/TermsAndConditionsDialog.tsx

import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Checkbox, FormControlLabel } from "@mui/material";

interface TermsAndConditionsDialogProps {
  open: boolean;
  onClose: (accepted: boolean) => void;
}

const TermsAndConditionsDialog: React.FC<TermsAndConditionsDialogProps> = ({ open, onClose }) => {
  const [accepted, setAccepted] = React.useState(false);

  const handleAcceptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccepted(event.target.checked);
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} aria-labelledby="terms-dialog-title">
      <DialogTitle id="terms-dialog-title">Terms and Conditions</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          {/* Add your terms and conditions content here */}
          By completing the Sign-up process you agree to be bound by the   <a 
        href="https://api.nomiadocs.com//document_records/37/get_generate/T&Cs(1)"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms and Conditions.
      </a>
        </Typography>
        <Typography variant="body1" paragraph>
          {/* Add more sections as needed */}
          Nomia will act in accordance with The Nomia  <a 
        href="https://api.nomiadocs.com//document_records/41/get_generate/Nomia Privacy Policy 2024)"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy.
      </a>
        </Typography>
        {/* Add more content as needed */}
        <Box mt={2}>
          <FormControlLabel
            control={<Checkbox checked={accepted} onChange={handleAcceptChange} />}
            label="I accept the Terms and Conditions"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onClose(accepted)} color="primary" disabled={!accepted}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
