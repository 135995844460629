import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DocumentTemplate } from "../../types/DocumentTemplate";
import Box from "@mui/material/Box";
import useActions from "../../app/hooks";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import { SUB_CATEGORY } from "../../globals";

export default function DocumentTemplateInfoForm(props: {
  parentCloseHandle: () => void;
  documentTemplate: DocumentTemplate;
}) {
  const actions = useActions();

  const [name, setName] = useState(props.documentTemplate.name);
  const [purpose, setPurpose] = useState(props.documentTemplate.purpose);
  const [category, setCategory] = useState(props.documentTemplate.category);
  const [nameError, setNameError] = React.useState("");
  const [categoryError, setCategoryError] = React.useState("");
  const [purposeError, setPurposeError] = React.useState("");

  function updateDocumentTemplate() {
    const newDocumentTemplate = Object.assign({}, props.documentTemplate, {
      name: name,
      purpose: purpose,
      category: category,
    });
    actions.updateDocumentTemplate(newDocumentTemplate);
    props.parentCloseHandle();
  }
  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }
  function handlePurposeChange(e: any) {
    if (e.target.value.trim() === "") {
      setPurposeError("Invalid purpose");
    } else setPurposeError("");
    setPurpose(e.target.value);
  }

  function handleCategoryChange(e: any) {
    if (e.target.value.trim() === "") {
      setCategoryError("Invalid category");
    } else setCategoryError("");
    setCategory(e.target.value);
  }

  return (
    <Dialog open={true} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>
        Document Template: {props.documentTemplate.name}
      </DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ px: 1 }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={name}
            fullWidth
            variant="standard"
            onChange={handleNameChange}
          />
          <TextField
            margin="dense"
            id="name"
            label="Category"
            value={category}
            fullWidth
            variant="standard"
            onChange={handleCategoryChange}
          />
          <TextField
            margin="dense"
            id="name"
            label={SUB_CATEGORY}
            value={purpose}
            fullWidth
            variant="standard"
            onChange={handlePurposeChange}
          />
          <TextField
            margin="dense"
            id="name"
            label="Authored by"
            defaultValue={props.documentTemplate.user_email}
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />

          {props.documentTemplate.doc_status == "locked" && (
            <TextField
              margin="dense"
              id="name"
              label="Locked by"
              defaultValue={props.documentTemplate.locked_by_email}
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
            />
          )}

          {/* 
<TextField
            margin="dense"
            id="Master Template__"
            label="Master Template"
            defaultValue={
              props.documentTemplate.document_template_master
         
                ?  props.documentTemplate.document_template_master.name
                : "Default Nomia Template"
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          /> */}

          <TextField
            margin="dense"
            id="name"
            label="Created"
            defaultValue={
              props.documentTemplate.created_at
                ? new Date(props.documentTemplate.created_at).toLocaleString()
                : ""
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            id="name"
            label="Last updated"
            defaultValue={
              props.documentTemplate.updated_at
                ? new Date(props.documentTemplate.updated_at).toLocaleString()
                : ""
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            margin="dense"
            id="name"
            label="Publish Status"
            defaultValue={
              props.documentTemplate.published_date
                ? new Date(
                    props.documentTemplate.published_date
                  ).toLocaleString()
                : "This Document has not been published"
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
            style={{ color: "#777777" }}
          />

          <TextField
            margin="dense"
            id="name"
            label="Deletion Status"
            defaultValue={
              props.documentTemplate.deleted_at
                ? new Date(props.documentTemplate.deleted_at).toLocaleString()
                : "This Document has not been deleted"
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={updateDocumentTemplate}
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            nameError !== "" || purposeError !== "" || categoryError !== ""
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
