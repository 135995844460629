import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { grey } from "@mui/material/colors";
import {
  IconButton,
  InputAdornment,
  LinearProgress,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  selectLibrary,
  setFilterString,
  setSortOrder,
} from "../librarySlice";
import {
  selectEditingDocumentTemplate,
  selectStatusState,
} from "../../editor/editorSlice";
import {
  selectPreviewStatus,
} from "../documentRecordsSlice";
import ConfirmDialog from "src/features/subscription/ConfirmDialog";

// Variable to store the timeout ID
let timeoutId: NodeJS.Timeout | null = null;

export function LibraryHeaderMenu() {
  const actions = useActions();
  const librarySlice = useAppSelector(selectLibrary);
  const selectStatusPreview = useAppSelector(selectPreviewStatus);
  const dispatch = useAppDispatch();
  const documentTemplateStatus = useAppSelector(selectStatusState);
  const documentTemplate = useAppSelector(selectEditingDocumentTemplate);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorDelete, setAnchorDelete] = useState(false);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteAllArchive = () => {
    // Implement here to delete all data
    setAnchorDelete(false);
  };

  const handleMenuItemClick = (selectedValue: string) => {
    // Update the librarySlice.sort value with the selected value
    dispatch(setSortOrder(selectedValue));
    // Close the menu
    handleClose();
  };
  const debouncedHandleChange = (value: string) => {
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null; // Reset timeoutId after clearing
    }
    // Set new timeout
    timeoutId = setTimeout(() => {
      actions.getDocumentTemplates();
    }, 800); // Adjust the delay as needed
  }; // Adjust the debounce delay as needed

  return (
    <>
      {/* <AppBar></AppBar> */}
      <AppBar
        position="fixed"
        sx={{ borderRadius: 0, paddingTop: 8, paddingRight: 0 }}
      >
        <Toolbar
          variant="dense"
          sx={{ bgcolor: grey[200], borderRadius: 0, paddingRight: 0 }}
        >
          <Box sx={{ width: "100%", paddingRight: 0 }}>
            <TextField
              sx={{ background: "#FFFFFF", borderRadius: 1 }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: librarySlice.filterString && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        dispatch(setFilterString(""));
                        actions.getDocumentTemplates();
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="Search bar"
              variant="standard"
              value={librarySlice.filterString}
              onChange={(e) => {
                dispatch(setFilterString(e.target.value));
                debouncedHandleChange(e.target.value);
                // dispatch(setFilterString(e.target.value));
                // actions.getDocumentTemplates();
              }}
            ></TextField>
          </Box>
          {/* {librarySlice.viewDeleted && (       <Tooltip title="Permanently Delete archived" placement="top">
              <IconButton onClick={()=>{setAnchorDelete(true)}}>
                <FolderDeleteIcon
                  style={{ fontSize: 30, outline: "none", color: "#7284A3" }}
                />
              </IconButton>
            </Tooltip>)} */}
          <Box>
            <Tooltip title="Sort by" placement="top">
              <IconButton onClick={handleClick}>
                <ImportExportIcon
                  style={{ fontSize: 30, outline: "none", color: "#7284A3" }}
                />
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* even ever search data is Appreared in by default Relevance... 
            this option only available even user searches */}
              {librarySlice.filterString != "" && (
                <MenuItem
                  onClick={() => handleMenuItemClick("Relevance")}
                  selected={librarySlice.sort === "Relevance"}
                  style={{
                    color:
                      librarySlice.sort === "Relevance" ? "#4C33FF" : "inherit",
                    fontWeight:
                      librarySlice.sort === "Relevance" ? "bold" : "normal",
                  }}
                >
                  Relevance
                </MenuItem>
              )}

              <MenuItem
                onClick={() => handleMenuItemClick("Alphabetical")}
                selected={librarySlice.sort === "Alphabetical"}
                style={{
                  color:
                    librarySlice.sort === "Alphabetical"
                      ? "#4C33FF "
                      : "inherit",
                  fontWeight:
                    librarySlice.sort === "Alphabetical" ? "bold" : "normal",
                }}
              >
                Alphabetical
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("Date")}
                selected={librarySlice.sort === "Date"}
                style={{
                  color: librarySlice.sort === "Date" ? "#4C33FF" : "inherit",
                  fontWeight: librarySlice.sort === "Date" ? "bold" : "normal",
                }}
              >
                Date
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>

        {anchorDelete && (
          <ConfirmDialog
            title="Permanently Delete archived items"
            content="Are you sure?  These items cannot be recovered once they are deleted"
            handleClose={() => setAnchorDelete(false)}
            handleConfirm={handleDeleteAllArchive}
          />
        )}
        {/* Progress Bar while openig/preview the Document on library screen  */}
        {(documentTemplateStatus === "loading" ||
          documentTemplateStatus === "failed" ||
          selectStatusPreview === "loading") &&
          documentTemplate === null && (
            <Box sx={{ zindex: 100000 }}>
              {documentTemplateStatus === "failed" ? (
                <LinearProgress variant="buffer" color="error" />
              ) : (
                <LinearProgress />
              )}
            </Box>
          )}
      </AppBar>
    </>
  );
}
