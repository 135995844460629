/* eslint-disable no-debugger */
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import {
  DocumentSection,
  DocumentTemplate,
} from "../../../types/DocumentTemplate";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { selectPreferences } from "../header/preferencesSlice";
import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LibraryContentConfigForm from "./libraryContent/LibraryContentForm";
import { selectCurrentWorkspaceRole } from "../../auth/authSlice";
import { useSelector } from "react-redux";
import { TIMEOUT } from "dns";
import { selectApprovalMode } from "../editorSlice";

export function SectionAdder(props: {
  organizationId: number;
  workspaceId: number;
  documentTemplate: DocumentTemplate;
  isFloatingInsert?: boolean | undefined;
  sectionBelowInsert?: DocumentSection;
}) {
  const actions = useActions();
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const viewPreferences = useAppSelector(selectPreferences);
  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const [isLibraryContentOpen, setIsLibraryContentOpen] =
    useState<boolean>(false);
  const [isLibraryContentFloat, setIsLibraryContentFloat] =
    useState<boolean>(false);

  function addSectionToEnd() {
    // Find the maximum component order
    const component_order =
      props.documentTemplate.sections!.length > 0
        ? Math.max(
            ...props.documentTemplate.sections!.map(function (o) {
              return o.component_order;
            })
          ) + 10
        : 0;
    actions.createSection({
      heading: "Section Heading",
      component_order: component_order,
      heading_level: 1,
      formatting: JSON.stringify({
        isBold: false,
        isItalic: false,
        isUnderline: false,
        isStandard: true,
        isCustomSettings: false,
      }),
    });
  }

  function insertSection() {
    const newSection = {
      heading: "Section Heading",
      component_order: props.sectionBelowInsert!.component_order,
      heading_level: 1,
      formatting: JSON.stringify({
        isBold: false,
        isItalic: false,
        isUnderline: false,
        isStandard: true,
        isCustomSettings: false,
      }),
    };
    actions.createSectionAboveSection({
      belowSection: props.sectionBelowInsert!,
      insertedSection: newSection,
    });
  }

  function insertLibraryContent() {
    setIsLibraryContentOpen(true);
  }

  const onClickLibraryContent = (e: any) => {
    if (props.isFloatingInsert) {
      setIsLibraryContentOpen(true);
      setIsLibraryContentFloat(true);
    } else {
      insertLibraryContent();
    }
  };
  const onClick = (e: any) => {
    if (!props.isFloatingInsert) addSectionToEnd();
    else {
      insertSection();
    }
  };

  // Float style?
  const style = props.isFloatingInsert
    ? { mx: 15, height: 0, flexGrow: 1, zIndex: 1, position: "relative" }
    : { mx: 15, height: "100vh" };

  const shouldDisplay = isLibraryContentOpen && isLibraryContentFloat;

  return (
    <Box sx={style}>
      {(currentWorkspaceRole == "CREATOR" ||
        currentWorkspaceRole == "PUBLISHER_CREATOR") &&
        !approvalsEnabled && (
          <Grow in={true}>
            <Divider>
              <Button
                onClick={onClick}
                onMouseDown={(e) => e.preventDefault()}
                startIcon={<AddCircleIcon fontSize="large" />}
                size="large"
                sx={{ paddingRight: 2 }}
              >
                {/* {props.isFloatingInsert ? "Insert" : "Add"} New Section */}
                Add New Section
              </Button>

              {!props.isFloatingInsert ? (
                <Button
                  onClick={onClickLibraryContent}
                  onMouseDown={(e) => e.preventDefault()}
                  startIcon={<AddCircleIcon fontSize="large" />}
                  size="large"
                  sx={{ paddingLeft: 2 }}
                >
                  Add Library Section
                </Button>
              ) : null}
            </Divider>
          </Grow>
        )}

      {/* {
//  shouldDisplay &&
isLibraryContentFloat &&
    <LibraryContentConfigForm
      closeDialogHandler={() => {
        setIsLibraryContentOpen(false)}
      }
      organizationId={props.organizationId}
      workspaceId={props.workspaceId}
      viewPreferences={viewPreferences}
      isFloatingInsert={shouldDisplay}
    />
 } */}

      {isLibraryContentOpen && !isLibraryContentFloat ? (
        <LibraryContentConfigForm
          closeDialogHandler={() => {
            setIsLibraryContentOpen(false);
          }}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          viewPreferences={viewPreferences}
        />
      ) : null}
    </Box>
  );
}
