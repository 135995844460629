import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { TableOfContents } from "./TableOfContent/TableOfContents";
import { isUserFromShareLink } from "../../document/TableElement/EditContent/helper";

// const drawerWidth = 260;

// REF: https://mui.com/components/drawers/#clipped-under-the-app-bar
export function LeftDrawer(props: any) {
  const drawer = <TableOfContents />;

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: "19vw",
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            mx: 0,
            width: "19vw",
            boxSizing: "border-box",
          },
        }}
        anchor="left"
      >
        {!isUserFromShareLink() && <Toolbar />}
        {drawer}
      </Drawer>
    </>
  );
}
