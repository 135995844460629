import * as React from "react";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Container } from "@mui/material";

import tempImg from "../auth/images/backgroundImageSignin.jpg";
import { nomiaTheme } from "src/theme";
const theme = createTheme();

export default function ErrorPage(props: { message: string | null }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      style={{
        backgroundImage: `url(${tempImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      {/* Red Overlay with 50% opacity */}
      <Box padding={10} borderRadius={10} bgcolor="rgba(255, 0, 0, 0.8)">
        <Container
          sx={{
            position: "relative",
            zIndex: 1,
            textAlign: "center",
            color: "white",
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 100, mb: 2 }} />{" "}
          <Typography variant="h4" gutterBottom>
            Oops! Something went wrong.
          </Typography>
          <Typography variant="body1">
            {props?.message || "An unexpected error occurred."}
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}
