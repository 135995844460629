/* eslint-disable no-debugger */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DocumentTemplate, Variable } from "../../types/DocumentTemplate";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import useActions from "../../app/hooks";
import { RootState } from "../../app/rootReducer";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectDocumentRecords,
  selectdocumentRecordsError,
  selectdocumentRecordsStatus,
} from "../library/documentRecordsSlice";
import {
  Alert,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { log } from "console";
import CreateDoc from "../../../src/Icons/MainHeader/Create.svg";
import { apiGetDocumentNameSuggestion } from "../../services/documentTemplatesAPI";
import { selectLibrary, setDocumentUniqueName } from "../library/librarySlice";
import { selectAuth, selectResponseError, setErrorMsg } from "../auth/authSlice";
export default function DocumentCreationForm(props: {
  parentCloseHandle: () => void;
  documentTemplate: DocumentTemplate;
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const errorUseSelector = useAppSelector(selectdocumentRecordsError);
  const statusUseSelector = useAppSelector(selectdocumentRecordsStatus);
  const { status } = useSelector((state: RootState) => state.documentRecords);
  const error = useSelector((state: RootState) => state.documentRecords.error);
  const [statusState, setstatusState] = useState<boolean>(false);
  const [isNameErrorState, setIsNameErrorState] = useState<boolean>(true);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isSuggest, isSetSuggest] = useState<boolean>(false);
  const [suggestion, setsuggestion] = useState(
    props.documentTemplate.name + getRandomInt(1000)
  );
  const selectErrorMsgState = useAppSelector(selectResponseError);

  const [isLoaderActive, setIsLoaderActive] = useState<boolean>(false);
  const libraryState:any = useAppSelector(selectLibrary);

  const [variables, setVariables] = useState<Variable[] | null>(
    copyVariables()
  );

  const authState = useAppSelector(selectAuth);
  const selectedOrg = authState.organizations?.find(
    (org) => org.id === authState.user?.default_organization_id
  );

  function getRandomInt(max: any) {
    return Math.floor(Math.random() * max);
  }
  const [name, setName] = useState(
    props.documentTemplate.name + getRandomInt(200)
    // libraryState.documentUniqueName!== undefined
    //   ? libraryState.documentUniqueName
    //   : props.documentTemplate.name
  );

  useEffect(() => {
    setName(
      libraryState.documentUniqueName
        ? libraryState.documentUniqueName
        : props.documentTemplate.name
    );
  }, [libraryState.documentUniqueName]);

  function copyVariables() {
    if (!props.documentTemplate.variables) return null as Variable[] | null;
    const newVariables = [] as Variable[];
    for (const v of props.documentTemplate.variables) {
      // if(!v.fillable_field){
      newVariables.push({ ...v });
      // }
    }
    return newVariables;
  }

  const onClose = () => {
    
    isSetSuggest(false);
    props.parentCloseHandle();
    dispatch(setDocumentUniqueName(undefined));
    dispatch(
      setErrorMsg({
        status: "idle",
        errorMsg: undefined,
        errorType: undefined,
      })
    );
  };

  useEffect(() => {
    ///
  }, [isSuggest]);
  // We are going to get these asynchonously so we need to check whether they have come in.
  if (props.documentTemplate.variables && variables === null) {
    setVariables(copyVariables());
  }

  useEffect(() => {
    if (String(error) === "isLoading") {
      setIsNameErrorState(false);
      isSetSuggest(false);
    }
    if (String(error) === "Conflict") {
      setIsLoaderActive(false);
      setIsNameErrorState(true);
      isSetSuggest(true);
    }
    if (error === undefined && isNameErrorState === false) {
      // setIsNameErrorState(false);
      isSetSuggest(false);
      setIsLoaderActive(false);
      dispatch(setDocumentUniqueName(undefined));
      props.parentCloseHandle();
    }
  }, [error]);

  // console.log("props in generation" , props.documentTemplate , isFirst , "first" , name , "name" , variables , "variabes")
  function createDocument() {
    setsuggestion(props.documentTemplate.name + getRandomInt(1000));
    if (isFirst) {
      if (name && variables) {
        actions.createDocumentRecord({
          documentTemplate: props.documentTemplate,
          name: name,
          variables: variables,
        });
        setIsLoaderActive(true);
      }
    }
    if (isNameErrorState) {
      setIsFirst(false);
      return;
    }
    if (name && variables && isFirst === false) {
      actions.createDocumentRecord({
        documentTemplate: props.documentTemplate,
        name: name,
        variables: variables,
      });
      setIsLoaderActive(true);
    }
    setIsFirst(false);
    // closeHandle();
  }
  function handleNameChange(e: any) {
    setIsNameErrorState(false);
    isSetSuggest(false);
    setName(e.target.value);
  }

  function updateVariableValue(variable: Variable, value: string) {
    const index = variables?.findIndex((v) => v.id == variable.id);
    if (index === undefined || index < 0) {
      console.error(
        `Cant find the index for variable ${variable.variable_name}.`
      );
      return;
    }
    variable.default_value = value;
    const newVariables = [...variables!];
    newVariables[index] = variable;
    setVariables(newVariables);
  }

  return (
    <Dialog
      open={true}
      onClose={() => {
        onClose()
        // isSetSuggest(false);
        // props.parentCloseHandle();
      }}
      maxWidth="lg"
    >
      <DialogTitle>
        <Grid container sx={{ alignItems: "center", justifyContent: "center" }}>
          <Grid
            item
            xs={3}
            style={{
              fontSize: "14pt",
              fontWeight: "bold",
              paddingBottom: "20px",
            }}
          >
            Document Name
          </Grid>
          <Grid item xs={9}>
            <TextField
              // placeholder="Document Name"
              fullWidth
              sx={{ minWidth: "500px" }}
              // label="Document Name"
              value={name}
              error={name.trim() == "" || selectErrorMsgState.errorType=="createDocumentRecord" && selectErrorMsgState.status==="failed"  ||( libraryState.creditCount===0 && !( selectedOrg && selectedOrg?.allow_negative_credits))  }
              helperText={
               ( libraryState.creditCount===0 && !( selectedOrg && selectedOrg?.allow_negative_credits)) ? "You have no credits left, please purchase more credits to continue":
                name.trim() == ""
                  ? "Document name cannot be blank"
                  :selectErrorMsgState.errorType=="createDocumentRecord" && selectErrorMsgState.status==="failed"  && isSuggest ?
                  selectErrorMsgState.msg
                  // "You have no credits left, please purchase more credits to continue"
                  // : 
                  // isSuggest 
                  // ? "Document with this name already exist Suggestion:" +
                  //   "   " +
                  //   suggestion
                  : " "
              }
              onChange={handleNameChange}
            ></TextField>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{ backgroundColor: "primary.main", color: "#FFFFFF" }}
              >
                <TableCell sx={{ color: "#fff" }}>Variable</TableCell>
                <TableCell align="left" sx={{ color: "#fff" }}>
                  Value
                </TableCell>
                {/* <TableCell align="right" sx={{color: "#fff"}}>Reset</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {variables?.length === 0 && (
                // <Alert severity="info" sx={{ m: 2 }}>
                <Typography sx={{ p: 2, fontWeight: "bold" }} variant="body1">
                  This template does not contain any variable.
                </Typography>
                // </Alert>
              )}
              {variables?.map(
                (variable) =>
                  !variable.fillable_field && (
                    <TableRow
                      key={variable.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <b>{variable.variable_name}</b>
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          fullWidth
                          value={variable.default_value}
                          onChange={(e: any) =>
                            updateVariableValue(variable, e.target.value)
                          }
                          variant="standard"
                          margin="none"
                          helperText={
                            variable.default_value.trim() == ""
                              ? "This will be passed as a blank string"
                              : null
                          }
                        ></TextField>
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
          {isLoaderActive && <LinearProgress sx={{ zIndex: 999 }} />}
        </TableContainer>

       {(props.documentTemplate.send_for_approvals ||  props.documentTemplate.needs_approval) &&( <Alert severity="warning">Changes are pending approval</Alert>)}
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={createDocument}
          type="submit"
          variant="contained"
          color="primary"
          disabled={name.trim() == "" || variables == null || isLoaderActive || (libraryState.creditCount===0 && !( selectedOrg && selectedOrg?.allow_negative_credits))}
        >
          Create Document
        </Button>
      </DialogActions>
    </Dialog>
  );
}
