import React, { useState } from "react";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import DoneIcon from "@mui/icons-material/Done";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  Container,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  selectPreferences,
  setPreferencesLogAllTextChecks,
  setPreferencesLogDebug,
  setPreferencesViewDebug,
  setPreferencesViewIDs,
  setPreferencesViewSections,
} from "./preferencesSlice";

export function PreferencesMenu() {
  const dispatch = useAppDispatch();
  const viewPreferences = useAppSelector(selectPreferences);

  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);

  function handleOpenMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorElSettings(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorElSettings(null);
  }

  return (
    <Box>
      <Tooltip title="Open preferences">
        <IconButton
        disableRipple
         style={{
          padding: "5px",
          paddingTop:"6px",
          paddingBottom:'0px',
          display: "center",
          /* Add any other styles you want */
        }}
        onClick={handleOpenMenu}>
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElSettings}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElSettings)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          key="setViewSections"
          onClick={(e) => {
            dispatch(setPreferencesViewSections(!viewPreferences.showSections));
            handleCloseMenu();
          }}
        >
          {viewPreferences.showSections ? (
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
          ) : null}
          <Typography textAlign="center">Show Sections</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          key="setViewIDs"
          onClick={(e) => {
            dispatch(setPreferencesViewIDs(!viewPreferences.showIDs));
            handleCloseMenu();
          }}
        >
          {viewPreferences.showIDs ? (
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
          ) : null}
          <Typography textAlign="center">Show IDs</Typography>
        </MenuItem>
        {/* <MenuItem
          key="setViewDebug"
          onClick={(e) => {
            dispatch(setPreferencesViewDebug(!viewPreferences.showDebug));
            handleCloseMenu();
          }}
        >
          {viewPreferences.showDebug ? (
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
          ) : null}
          <Typography textAlign="center">Show Debug</Typography>
        </MenuItem> */}
        {/* <MenuItem
          key="setLogDebug"
          onClick={(e) => {
            dispatch(setPreferencesLogDebug(!viewPreferences.logDebug));
            handleCloseMenu();
          }}
        >
          {viewPreferences.logDebug ? (
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
          ) : null}
          <Typography textAlign="center">Log Debug Info</Typography>
        </MenuItem> */}
        {/* <MenuItem
          key="setLogAllChecks"
          onClick={(e) => {
            dispatch(
              setPreferencesLogAllTextChecks(
                !viewPreferences.logAllTextChecking
              )
            );
            handleCloseMenu();
          }}
        >
          {viewPreferences.logAllTextChecking ? (
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
          ) : null}
          <Typography textAlign="center">Log All Content Checks</Typography>
        </MenuItem> */}
      </Menu>
    </Box>
  );
}
