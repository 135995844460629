import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import { Dialog, DialogTitle, Input } from "@mui/material";
import React, { useEffect } from "react";
import { apiGetOrganizationList } from "../../../services/organizationAPI";
import { selectAuth, selectCreditStatus, selectUserRole } from "../../auth/authSlice";
import useActions, { useAppSelector } from "../../../app/hooks";
import { Organization, USER_ROLE } from "../../../types/User";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { MAX_CREDIT_LIMIT } from "../../../globals";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OrganizationDeleteForm from "./OrganiationDeleteForm";

interface Organization_ {
  metric_limit: number;
  subscription_cycle: string;
  id: number;
  name: string;
  allow_negative_credits:boolean
}
export default function ManageOrganizations(props: {
  closeDialogHandler: any;
}) {
  const actions = useActions();
  const userRole = useAppSelector(selectUserRole);
  const authState = useAppSelector(selectAuth);
  const creditstatus = useAppSelector(selectCreditStatus);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [deleteConfig, setDeleteConfig] = React.useState(false)
  const [negitiveCount,setNegitiveCount] = React.useState(false)

  // all Organzations List
  const [organizationData, setOrganizationData] = React.useState([]);
  // Update Dailoge Flag
  const [isEditDialogOpen, setEditDialogOpen] = React.useState(false);
  //Updated Count
  const [updatedCreditCount, setUpdatedCrditCount] = React.useState(0);
  const [updatedOrganizationName, setUpdatedOrganizationName]= React.useState("")
  //user Selected Organization
  const [selectedOrganization, setSelectedOrganization] =
    React.useState<Organization_ | null>(null);
  const [requestStatus, setRequestStatus] = React.useState("idle");

  const dialogStyle = {
    // maxWidth: "600px",
    width:"90vh",
    margin: "0 auto",
  };

  const fetchData = async () => {
    try {
      setRequestStatus("loading");
      const response = await apiGetOrganizationList();

      // setOrganizationData(response.organizations);

      // Sort organizations based on name
      const sortedOrganizations = response.organizations.sort(
        (a: any, b: any) => a.name.localeCompare(b.name)
      );

      setOrganizationData(sortedOrganizations);

      setRequestStatus("idle");
    } catch (error) {
      setRequestStatus("failed");
      console.error(
        "An error occurred while fetching organization admins:",
        error
      );
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (creditstatus == "success") {

      fetchData();
      fetchData();
      enqueueSnackbar("Organization succesfully updated", {
        variant: "success",
      });
    } else if (creditstatus == "failed") {
      enqueueSnackbar("Unauthorized user", {
        variant: "error",
      });
    }
  }, [creditstatus]);

  const handleClose = () => {
    setUpdatedOrganizationName("")
    setUpdatedCrditCount(0);
    props.closeDialogHandler();
  };

  /// Edit Dialog Box
  const handleEditClick = (organization: Organization_) => {
    setUpdatedCrditCount(organization.metric_limit);
    setUpdatedOrganizationName(organization.name)
    setNegitiveCount(organization.allow_negative_credits)
    setSelectedOrganization(organization);

    setEditDialogOpen(true);
  };

  const handleDeleteClick=(organization: Organization_)=>{
    setSelectedOrganization(organization); 
    setDeleteConfig(true)
  }

  const handleCreditChange = (e: any) => {
    const inputValue = e.target.value;
    const newValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters
    setUpdatedCrditCount(parseInt(newValue));
  };


  const handleNameUpdate= (e:any)=>{
    setUpdatedOrganizationName(e.target.value)
  }

  const handleUpdateCredit = (e: any) => {
    if (userRole === USER_ROLE.SUPER_USER && selectedOrganization) {
      actions.updateOrganizationCredits({
        organizationID: selectedOrganization.id,
        updatedCredit: updatedCreditCount,
        organizationName : updatedOrganizationName ,
        allowNegitiveCredits:negitiveCount 
      });
    }
    
     fetchData();
    handleCloseEdit();
  };

  const handleCloseEdit = () => {
    setUpdatedOrganizationName("")
    setUpdatedCrditCount(0);
    setEditDialogOpen(false);
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose} style={dialogStyle}>
        <DialogTitle style={{ textAlign: "left", fontWeight: "bold" }}>
          Manage Organizations
        </DialogTitle>
        {requestStatus == "idle" ? (
          <Box style={{ padding: "16px" , paddingTop:0 }}>
            {(organizationData || []).map((organization: Organization_) => (
              <Grid
                container
                spacing={1}
                key={organization.id}
                sx={{ padding: 1 ,paddingTop:0 }}
              >
                <Grid item xs={7}>
                  <Input sx={{ fontSize: "0.9em" }} value={organization.name} fullWidth 
                  readOnly/>
                </Grid>
                <Grid item xs={2}>
                  <Input sx={{ fontSize: "0.9em" }}value={organization.metric_limit} fullWidth 
                  readOnly
                  />
                </Grid>
                <Grid item xs={1.5}  textAlign="center">
                  <IconButton  
                  disabled={organization.id=== authState.user?.default_organization_id}

                    onClick={() => handleDeleteClick(organization)}
                  >
                    <DeleteForeverIcon  sx={{fontSize: "0.9em"}} />
                  </IconButton >
                  </Grid>
                <Grid item xs={1.5} >

                <IconButton
         
                color="primary"
                title="Edit"
                    onClick={() => handleEditClick(organization)}
                  >
                    <EditIcon  sx={{fontSize: "0.9em"}} />
                  </IconButton>

                  {/* <Button
                    variant="contained"
                    //   color="primary"
                    onClick={() => handleEditClick(organization)}
                  >
                    <EditIcon />
                  </Button> */}
                </Grid>
              </Grid>
            ))}
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "180px",
              width: "400px",
            }}
          >
            {requestStatus == "loading" ? (
              <CircularProgress />
            ) : (
              <Typography fontWeight="bold">Unable to fetch data</Typography>
            )}
          </Box>
        )}
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={isEditDialogOpen} onClose={handleCloseEdit}>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Update Organization
        </DialogTitle>
        {/* { requestStatus=="failed" && (  <Box>

    request failed
  </Box>)
} */}

        <Box style={{ padding: "16px" }}>
          <TextField
            label="Organization Name"
            fullWidth
            value={updatedOrganizationName}
            onChange={(e)=> handleNameUpdate(e)}
            disabled = { selectedOrganization!=null && selectedOrganization!.id=== authState.user?.default_organization_id}
          />

          <TextField
            sx={{ my: 2.5 }}
            label="New Credit Count"
            fullWidth
            value={
              selectedOrganization
                ? Number.isNaN(updatedCreditCount)
                  ? " "
                  : updatedCreditCount
                : ""
            }
            error={updatedCreditCount > MAX_CREDIT_LIMIT}
            helperText={
              updatedCreditCount > MAX_CREDIT_LIMIT ? "credit Limit exceed" : ""
            }
            onChange={(e) => handleCreditChange(e)}
          />

<Box>
            <Typography
              sx={{
                display: "inline",
                marginBottom: "15px",
                padding:0
              }}
            >
                 Allow negative credits?
         
            </Typography>
            <Checkbox
              checked={negitiveCount}
              onChange={()=>setNegitiveCount(!negitiveCount)}
             
            />
          </Box>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseEdit}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                selectedOrganization === null ||
                Number.isNaN(updatedCreditCount) ||
                updatedCreditCount > MAX_CREDIT_LIMIT
              }
              onClick={(e) => {
                handleUpdateCredit(e);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {
        deleteConfig &&  
        <OrganizationDeleteForm 
        organization ={selectedOrganization! }
        closeDialogHandler={()=>{setDeleteConfig(false)}}


        />
      }
    </>
  );
}
