
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  apiDeleteDocumentTemplate,
  apiGetDocumentTemplates,
  apiUndeleteDocumentTemplate,
} from "../../services/documentTemplatesAPI";
import { DocumentRecord, DocumentTemplate } from "../../types/DocumentTemplate";

export interface LibraryState {
  documentRecords: DocumentRecord[];
  status: "idle" | "loading" | "failed";
  error: string | undefined;
  bulkRecordsStatus:string;
  previewStatus:any
}

const initialState: LibraryState = {
  documentRecords: [] as DocumentRecord[],
  status: "idle",
  error: undefined,
  bulkRecordsStatus:"idle",
  previewStatus:"idle"
};

export const documentRecordsSlice = createSlice({
  name: "documentRecords",
  initialState,
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<{
        status: "idle" | "loading" | "failed";
        error: string | undefined;
      }>
    ) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setPreviewStatus: (state, action: PayloadAction<string >) => {
      state.previewStatus = action.payload;
    },

    
    setRemoveRecord: (
      state,
      action: PayloadAction<{
        documentID: number;
        isRecord: boolean;
      }>
    ) => {
      state.documentRecords = state.documentRecords.filter(
        (record) => record.id !== action.payload.documentID
      );
    },


    setBulkRecordsStatus: (state, action: PayloadAction<string >) => {
      state.bulkRecordsStatus = action.payload;
    },
    setClearLibraryRecords: (state) => {
      state.documentRecords=[]
    },
    setDocumentRecords: (state, action: PayloadAction<DocumentRecord[]>) => {
      state.documentRecords = action.payload;
    },
  },
  // extraReducers: (builder) => {},
});

export const { setStatus, setError, setRemoveRecord, setClearLibraryRecords,setBulkRecordsStatus, setDocumentRecords ,setPreviewStatus  } =
  documentRecordsSlice.actions;

//Selectors
export const selectDocumentRecords = (state: RootState) => state.library;
export const selectPreviewStatus=(state:RootState)=> state.documentRecords.previewStatus
export const selectdocumentRecordsError= (state: RootState) => state.documentRecords.error;
export const selectdocumentRecordsStatus= (state: RootState) => state.documentRecords.status;
export const  selectBulkRecordsStatus=(state:RootState)=> state.documentRecords.bulkRecordsStatus
export default documentRecordsSlice.reducer;
