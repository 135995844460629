import { Box, Icon, Typography } from "@mui/material";

import folder from "../LeftDrawer/Icons/folderIcon.svg";

import groupByFolderIcon from "../LeftDrawer/Icons/group by Icon.svg";

export function GroupFolderIcons(props: {
  text: string;
  type: string;
  category?: string;
  subcategory?: string;
}) {
  return (
    <>
      {props.type == "PDF" ? (
        <Box sx={{ position: "relative", textAlign: "left" }} 
        title= {props.text}
        
        >
          <img src={folder} alt="FolderIcon" width="150" height="190" />

          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "10px",
              // transform: "translate(-50%, -50%)",
              fontWeight: "bold",
              color: "black",
              fontSize: "15px", // Adjust the font size as needed
              maxWidth: "120px", // Limit the maximum width to prevent overflowing
              whiteSpace: "nowrap", // Prevent text from wrapping
              overflow: "hidden", // Hide any overflowing text
             textOverflow: "ellipsis", // Show ellipsis (...) for trimmed text
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "11px", fontWeight: "bold" ,
              whiteSpace: "nowrap", // Prevent text from wrapping
              overflow: "hidden",  // Hide overflow text
              textOverflow: "ellipsis", // Add ellipsis to overflow text
            }}
            >
              {props.text}
            </Typography>

            {/* Category */}
            <Typography
              variant="body2"
              color="black"
              sx={{ fontSize: "10px", padding: "0px", paddingLeft: "5px" ,
              whiteSpace: "nowrap", // Prevent text from wrapping
              overflow: "hidden",  // Hide overflow text
              textOverflow: "ellipsis", // Add ellipsis to overflow text
            }}
            >
              {props.category}
            </Typography>

            {/* Purpose */}
            <Typography
              variant="body2"
              color="black"
              sx={{ fontSize: "10px", padding: "0px", paddingLeft: "5px"  ,
              whiteSpace: "nowrap", // Prevent text from wrapping
              overflow: "hidden",  // Hide overflow text
              textOverflow: "ellipsis", // Add ellipsis to overflow text
            }}
            >
              {props.subcategory}
            </Typography>
            <Box
                  sx={{
                    width: "70px",
                    height: "16px",
                    backgroundColor: "#7c7c7c",
                    borderRadius: "3px",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "white",
                    // fontWeight: 'bold',
                    // textTransform: 'uppercase',
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    fontSize: "12px", // Adjust the font size as needed
                    padding: "2px",
                    paddingLeft: "2px",
                    fontFamily: "MarkPro",
                    marginTop:"10px",
                    marginLeft:"0px",
                    paddingTop:"5px",
                    paddingBottom:"3px"
                  }}
                >
                  {"PDF"}
                </Box>
          </div>
        </Box>
      ) : (
        <Box sx={{ position: "relative", textAlign: "center" }}
        
        title= {props.text}
        >
          <img src={groupByFolderIcon} alt="FolderIcon" width="150" height="190" />

          <div
            style={{
              position: "absolute",
              top: "25%",
              left: "45%",
              transform: "translate(-50%, -50%)",
              fontWeight: "bold",
              color: "black",
              fontSize: "15px", // Adjust the font size as needed
              maxWidth: "130px", // Limit the maximum width to prevent overflowing
              whiteSpace: "nowrap", // Prevent text from wrapping
              overflow: "hidden", // Hide any overflowing text
              // textOverflow: "ellipsis", // Show ellipsis (...) for trimmed text
            }}
          >
           <Typography
              variant="h5"
              sx={{ fontSize: "12px", fontWeight: "bold" ,
              whiteSpace: "nowrap", // Prevent text from wrapping
              overflow: "hidden",  // Hide overflow text
              textOverflow: "ellipsis", // Add ellipsis to overflow text
            }}
            >
              {props.text}
            </Typography>
          </div>
        </Box>
      )}
    </>
  );
}
