import {
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
} from "../types/DocumentTemplate";
import { client } from "./client";
import { compareComponentOrder } from "./documentTemplatesAPI";

export function apiInviteUserForComments(
  organization_id: number,
  workspace_id: number,
  document_template_id: number,
  body: any
) {
  return client.post(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/commenters`,
    body
  );
}

export function apiGetCommentingUsers(
  organization_id: number,
  workspace_id: number,
  document_template_id: number
) {
  return client.get(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/commenters`
  );
}

export function apiLockUnlockCommenters(
  organization_id: number,
  workspace_id: number,
  document_template_id: number,
  commenter_id: number,
  value: boolean
) {
  return client.put(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/commenters/${commenter_id}/lock/${value}`
  );
}

export async function apiGetCommentsData(commenter_id: number) {
  const response: any = await client.get(
    `?fromShare=true&commenter_id=${commenter_id}`
  );

  if (response?.data?.error) {
    return response;
  }

  const orig = response.data;
  // Function to parse import text into an array of objects
  const parseImportText = (importText: string) =>
    importText.split("\n").map((line) => {
      const [partType, partText] = line.split(";");
      return { part_type: partType, part_text: partText };
    });

  // Function to create a new section object with necessary properties
  const createSection = (section: any, index: number) => ({
    ...section,
    subsections: [],
    component_order: orig.document_section_orders[index],
    pending_addition: orig.document_section_additions[index],
  });

  // Function to create a new subsection object with necessary properties
  const createSubsection = (subsection: any, index: number) => ({
    ...subsection,
    elements: [],
    component_order: orig.document_sub_section_orders[index],
    pending_addition: orig.document_sub_section_additions[index],
    pending_deleted_at:
      orig.deleted_sub_sections &&
      orig.deleted_sub_sections.includes(subsection.id)
        ? "soft-deleted"
        : subsection.pending_deleted_at,
  });

  // Create a new DocumentTemplate object with processed data
  const dc: DocumentTemplate = {
    ...orig.document_template,
    commenter_id: orig.commenter_id,
    fullname: orig.fullname,
    email: orig.email,
    sections: orig.document_sections.map(createSection),
    variables: orig.document_variables,
    included_subSections: orig.sections_sub_section,
    included_section: orig.included_sections,
    sections_sub_section: orig.sections_sub_section,
    is_version_show: orig.is_version_show,
    imported_sections: orig.imported_sections,
    imported_sub_sections: orig.imported_sub_sections,
    is_undo_show: orig.is_undo_show,
    references: orig.document_references,
    is_version_available: orig.is_version_available,
    element_version: orig.document_elements_versions,
    deleted_sub_sections: orig.deleted_sub_sections,
    import_text: parseImportText(orig.import_text),
  };

  // Initialize dictionaries to store sections and subsections for efficient assignment
  const sectionDict: Record<number, DocumentSection> = {};
  const subsectionDict: Record<number, DocumentSubsection> = {};

  // Populate the section dictionary
  dc.sections?.forEach((section, index) => {
    sectionDict[section.id] = section;
  });

  // Populate the subsection dictionary and associate subsections with their parent sections
  orig.document_sub_sections?.forEach((subsection: any, index: number) => {
    const parentSectionId = orig.document_sub_section_parents[index];
    const parentSection = sectionDict[parentSectionId];
    if (parentSection) {
      const newSubsection = createSubsection(subsection, index);
      parentSection.subsections!.push(newSubsection);
      subsectionDict[subsection.id] = newSubsection;
    }
  });
  // Populate the document elements within their respective subsections
  orig.document_elements?.forEach((documentElement: any) => {
    const subsection = subsectionDict[documentElement.document_sub_section_id];
    if (subsection) {
      subsection.elements!.push({
        ...documentElement,
        pending_addition: subsection.pending_addition,
        difference_content:
          orig.difference_content && orig.difference_content[subsection.id]
            ? orig.difference_content[subsection.id]
            : documentElement.difference_content,
        heading_level: orig.heading_levels[0][subsection.id] || 2, // Access heading level
      });
    }
  });
  // Sort sections and subsections by component order
  dc.sections?.sort(compareComponentOrder);
  dc.sections?.forEach((section) =>
    section.subsections?.sort(compareComponentOrder)
  );
  // Assign the document template master from the original data
  dc.document_template_master = orig.document_template_master;

  // console.log("response document Template" , dc)
  // Return the processed DocumentTemplate object
  return dc;
}

export async function apiDeleteCommenter(
  organization_id: number,
  workspace_id: number,
  document_template_id: number,
  commenter_id: number
) {
  return await client.delete(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/commenters/${commenter_id}`
  );
}

//COMMENTS API GOES HERE !!!!

export async function apiAddComments(
  organization_id: number,
  workspace_id: number,
  document_template_id: number,
  document_section_id: number | null | undefined,
  body: any
) {
  return await client.post(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/document_section/${document_section_id}`,
    body
  );
}

export async function apiGetComments(
  organization_id: number,
  workspace_id: number,
  document_template_id: number,
  body: any
) {
  return await client.post(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/get_comments`,
    body
  );
}

export async function apiGetCommentsCount(
  organization_id: number,
  workspace_id: number,
  document_template_id: number
) {
  return await client.get(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/comment_counts`
  );
}

export async function apiUpdateComment(
  organization_id: number,
  workspace_id: number,
  document_template_id: number,
  document_section_id: number | null | undefined,
  comment_id: number,
  body: any
) {
  return await client.put(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/document_section/${document_section_id}/comment/${comment_id}`,
    body
  );
}

export async function apiDeleteComment(
  organization_id: number,
  workspace_id: number,
  document_template_id: number,
  document_section_id: number | null | undefined,
  comment_id: number,
  body: any
) {
  return await client.delete(
    `/organization/${organization_id}/workspace/${workspace_id}/document_template/${document_template_id}/document_section/${document_section_id}/comment/${comment_id}`,
    body
  );
}
