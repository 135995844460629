import { client } from "./client";
import {
  ApprovalPayload,
  DocumentElement,
  DocumentRecord,
  DocumentSection,
  DocumentSectionCreateRequest,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateCreateRequest,
  DocumentsFilters,
  Reference,
  ReferenceCreateRequest,
  TitleSectionCreateRequest,
  Variable,
  VariableCreateRequest,
  Workspace,
  ContentType,
} from "../types/DocumentTemplate";
// TODO: Remove
import { host } from "./client";
import { getLocalUserToken } from "./userAPI";
import axios from "axios";
import {
  LIST_OF_FIGURES,
  LIST_OF_TABLES,
  TABLE_OF_CONTENTS,
  TABLE_OF_CONTENTS_SECTION,
} from "../globals";
import { decryptAES } from "./encryption";
import getEncrypted from "./encryptionClient";
import { getDocumentImagePreview } from "../app/actions";
export function apiGetDocumentTemplates(
  organizationId: number,
  workspaceId: number
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates`
  );
}

export function apiGetDocumentRecord(record: DocumentRecord) {
  console.log(
    "🚀 ~ file: documentAPI.ts ~ line 47 ~ apiGetDocumentRecord ~ record",
    record.hidden_link
  );
  return client.get(
    `/document_records/${record.hidden_link}/get_generate/${record.name}`,
    { isBinary: true }
  );
}

export function apiGetFilteredDocumentTemplates(
  organizationId: number,
  workspaceId: number,
  LibraryDocumentFilter: DocumentsFilters
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/template_filters`,
    LibraryDocumentFilter
  );
}

// export function apiGetPurposes(
//   organizationId: number,
//   workspaceId: number
// ) {
//   return client.get(
//     `/organizations/${organizationId}/workspaces/${workspaceId}/document_records`
//   )
//   .then(response => {
//     //const purposes = response.data.map(record => record.purpose);
//     return purposes;
//   });
// }

export async function apiGetCategory(organization: number, workspace: number) {
  try {
    const response = await client.get(
      `/organizations/${organization}/workspaces/${workspace}/document_templates/get_purpose_category`
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving category:", error);
  }
}

export async function apiGetVersionElements(
  organization: number,
  workspace: number,
  documentID: number,
  sectionID: number,
  subsectionID: number
) {
  try {
    const response = await client.get(
      `/organizations/${organization}/document_templates/${documentID}/document_sections/${sectionID}/document_sub_sections/${subsectionID}/document_elements_versions`
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving category:", error);
  }
}

export function apiRegenerateRecord(
  documentRecordId: number,
  documentRecordName: string
) {
  return client.post(
    `/document_records/${documentRecordId}/generate/${documentRecordName}`
  );
}

export function apiGetDocumentRecords(
  organizationId: number,
  workspaceId: number
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_records`
  );
}

export function apiCreateDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateCreateRequest: DocumentTemplateCreateRequest
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates`,
    documentTemplateCreateRequest
  );
}

// export function apiCreateDocumentTemplate(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplateCreateRequest: DocumentTemplateCreateRequest
// ) {
//   return client.post(
//     `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates`,
//     documentTemplateCreateRequest
//   );
// }
// export async function apiCreateDocumentTemplate(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplateCreateRequest: DocumentTemplateCreateRequest
// ) {
//   try {
//     const response = await client.post(
//       `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates`,
//       documentTemplateCreateRequest
//     );
//     return response.data;
//   } catch (error) {
//  //   window.alert("error in back api");
//     console.error("Error creating document template:", error);
//     throw error;
//   }
// }

export async function apiImportDocumentRecord(
  organizationId: number,
  workspaceId: number,
  file: File
) {
  const url = `${host}/organizations/${organizationId}/workspaces/${workspaceId}/document_templates_upload`;
  let headers = {};
  // Content-Type: image/png
  const token = getLocalUserToken();
  if (token) {
    headers = {
      Authorization: token,
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }
  const contentBuffer = await readFileAsync(file);
  const response = await window.fetch(url, {
    method: "POST",
    body: contentBuffer as BodyInit,
    headers: headers,
  });
  const data = await response.json();
  if (response.ok) {
    // Return a result object similar to Axios
    return {
      status: response.status,
      data,
      headers: response.headers,
      url: response.url,
    };
  }
  throw new Error(response.statusText);
}

export async function apiImportDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  file: File
) {
  const url = `${host}/organizations/${organizationId}/workspaces/${workspaceId}/document_templates_upload`;
  let headers = {};
  // Content-Type: image/png
  const token = getLocalUserToken();
  if (token) {
    headers = {
      Authorization: token,
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }
  const contentBuffer = await readFileAsync(file);
  const response = await window.fetch(url, {
    method: "POST",
    body: contentBuffer as BodyInit,
    headers: headers,
  });
  const data = await response.json();
  if (response.ok) {
    // Return a result object similar to Axios
    return {
      status: response.status,
      data,
      headers: response.headers,
      url: response.url,
    };
  }
  throw new Error(response.statusText);
}

export function apiImportDocumentTemplateFromDocConclude(
  organizationId: number,
  workspaceId: number,
  dtID: number,
  dtName: string,
  dtCategory: string,
  dtSubCategory: string,
  dtType: boolean
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${dtID}/conclude_import`,
    {
      name: dtName,
      category: dtCategory,
      sub_category: dtSubCategory,
      is_once_off: dtType,
    }
  );
}

export function apiFavouriteDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  dt: DocumentTemplate
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${dt.id}/favourite`,
    {}
  );
}

export function apiUnfavouriteDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  dt: DocumentTemplate
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${dt.id}/unfavourite`,
    {}
  );
}

export function apiApproveDocumentSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  approveSectionID: number | null,
  declineSectionID: number | null
) {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/section_versioning`,
    {
      approved_section: approveSectionID,
      declined_section: declineSectionID,
    }
  );
}

export function compareComponentOrder(a: any, b: any) {
  if (a.component_order === b.component_order)
    console.warn(
      `Components have the same order (${a.component_order}), sorting is not on a unique ID`
    );
  return a.component_order - b.component_order;
}

// export async function apiGetDocumentTemplate(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplateId: number
// ) {
//   const response = await client.get(
//     `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateId}`
//   );

//   const orig = response.data;
//   const versionedSubsection: { [key: number]: DocumentSubsection } = {};

//   // Convert the return structure to the correct DocumentTemplate type
//   const dc: DocumentTemplate = Object.assign(orig.document_template, {
//     sections: orig.document_sections,
//     variables: orig.document_variables,
//     included_subSections: orig.sections_sub_section,
//     included_section: orig.included_sections,
//     sections_sub_section:orig.sections_sub_section,
//     is_version_show:orig.is_version_show,
//     references: orig.document_references,
//     is_version_available:orig.is_version_available,
//     element_version:orig.document_elements_versions,
//     // soft Deleted Library content
//     deleted_sub_sections:orig.deleted_sub_sections,
//     import_text: (orig.import_text.split("\n") as string[]).map((_) => {
//       const parts = _.split(";");

//       return {
//         part_type: parts.shift(),
//         part_text: parts.join(";"),
//       };
//     }),
//   });

//   // Make a dictionary of sections for efficient assignment later
//   const sectionDict: { [key: number]: DocumentSection } = {};
//   dc.sections?.forEach((section, index) => {
//     section.subsections = [];
//     section.component_order = orig.document_section_orders[index];
//     section.pending_addition = orig.document_section_additions[index];
//     sectionDict[section.id] = section;
//   });

//   // Do the same for subsections
//   const subsectionDict: { [key: number]: DocumentSubsection } = {};
//   orig.document_sub_sections?.forEach((subsection: any, index: number) => {
//     subsection.elements = [];
//     subsection.component_order = orig.document_sub_section_orders[index];
//     subsection.pending_addition = orig.document_sub_section_additions[index];
//     // ??if the subsection is deleted or not
//     subsection.pending_deleted_at = orig.deleted_sub_sections && orig.deleted_sub_sections.includes(subsection.id) ? "soft-deleted" : subsection.pending_deleted_at;
//     //Add the subsection.
//     sectionDict[orig.document_sub_section_parents[index]].subsections!.push(
//       subsection
//     );
//     subsectionDict[subsection.id] = subsection;
//   });

//   // Add the document elements
//   orig.document_elements?.forEach((documentElement: any, index: number) => {
//     //console.log("A", subsectionDict[documentElement.document_sub_section_id].pending_addition);
//     subsectionDict[documentElement.document_sub_section_id].elements!.push({
//       ...documentElement,
//       pending_addition:
//         subsectionDict[documentElement.document_sub_section_id]
//           .pending_addition,
//     });
//   });

//   /*
//   orig.published_document_elements?.forEach((documentElement: any, index: number) => {
//     subsectionDict[documentElement.document_sub_section_id].pubElements!.push(
//       documentElement
//     );
//   });
//   */

//   // Sort the objects by their component and content orders
//   // TODO: Optimize if this becomes unwieldy
//   dc.sections!.sort(compareComponentOrder);
//   dc.sections!.forEach((section) => {
//     section.subsections!.sort(compareComponentOrder);
//   });

//   dc.document_template_master = orig.document_template_master;
//   return dc;
// }
// // Optimization done copy of the orgina
// export async function apiGetDocumentTemplate(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplateId: number
// ) {
//   // Fetch document template data from the API
//   const response = await client.get(
//     `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateId}`
//   );
//   // Extract the original data from the API response
//   const orig = response.data;
//   // Function to parse import text into an array of objects
//   const parseImportText = (importText: string) =>
//     importText.split("\n").map((line) => {
//       const [partType, partText] = line.split(";");
//       return { part_type: partType, part_text: partText };
//     });

//   // Function to create a new section object with necessary properties
//   const createSection = (section: any, index: number) => ({
//     ...section,
//     subsections: [],
//     component_order: orig.document_section_orders[index],
//     pending_addition: orig.document_section_additions[index],
//   });

//   // Function to create a new subsection object with necessary properties
//   const createSubsection = (subsection: any, index: number) => ({
//     ...subsection,
//     elements: [],
//     component_order: orig.document_sub_section_orders[index],
//     pending_addition: orig.document_sub_section_additions[index],
//     pending_deleted_at:
//       orig.deleted_sub_sections &&
//       orig.deleted_sub_sections.includes(subsection.id)
//         ? "soft-deleted"
//         : subsection.pending_deleted_at,
//   });

//   // Create a new DocumentTemplate object with processed data
//   const dc: DocumentTemplate = {
//     ...orig.document_template,
//     sections: orig.document_sections.map(createSection),
//     variables: orig.document_variables,
//     included_subSections: orig.sections_sub_section,
//     included_section: orig.included_sections,
//     sections_sub_section: orig.sections_sub_section,
//     is_version_show: orig.is_version_show,
//     imported_sections: orig.imported_sections,
//     imported_sub_sections: orig.imported_sub_sections,
//     is_undo_show:orig.is_undo_show,
//     references: orig.document_references,
//     is_version_available: orig.is_version_available,
//     element_version: orig.document_elements_versions,
//     deleted_sub_sections: orig.deleted_sub_sections,
//     import_text: parseImportText(orig.import_text),
//   };

//   // Initialize dictionaries to store sections and subsections for efficient assignment
//   const sectionDict: Record<number, DocumentSection> = {};
//   const subsectionDict: Record<number, DocumentSubsection> = {};
//   // Populate the section dictionary
//   dc.sections?.forEach((section, index) => {
//     sectionDict[section.id] = section;
//   });

//   // Populate the subsection dictionary and associate subsections with their parent sections
//   orig.document_sub_sections?.forEach((subsection: any, index: number) => {
//     const parentSectionId = orig.document_sub_section_parents[index];
//     const parentSection = sectionDict[parentSectionId];
//     if (parentSection) {
//       const newSubsection = createSubsection(subsection, index);
//       const documentElement=  orig.document_elements?.find((_element:any)=>_element.document_sub_section_id==subsection.id)
//       if(documentElement){
//          newSubsection.elements!.push({
//         ...documentElement,
//         pending_addition: subsection.pending_addition,
//         difference_content: orig.difference_content && orig.difference_content [subsection.id] ? orig.difference_content[subsection.id] : documentElement.  difference_content,
//        heading_level:orig.heading_levels[0][subsection.id] || 2 // Access heading level
//       });
//     }
//       parentSection.subsections!.push(newSubsection);
//     }
//   });
//   // console.log( "apiGetDocumentTemplate","orig.document_sections" ,  orig.document_sections , "dc.sections" ,   dc.sections , "orig.document_sub_sections" ,orig.document_sub_sections, "subsectionDict" ," sectionDict" , sectionDict)
//   // // Populate the document elements within their respective subsections
//   // orig.document_elements?.forEach((documentElement: any) => {
//   //   const subsection = subsectionDict[documentElement.document_sub_section_id];
//   //   console.log("subsection in element" , subsection)
//   //   if (subsection) {
//   //     subsection.elements!.push({
//   //       ...documentElement,
//   //       pending_addition: subsection.pending_addition,
//   //      heading_level:orig.heading_levels[0][subsection.id] || 2 // Access heading level
//   //     });
//   //   }
//   // });

//   // Sort sections and subsections by component order
//   dc.sections?.sort(compareComponentOrder);
//   dc.sections?.forEach((section) => section.subsections?.sort(compareComponentOrder));
//   // Assign the document template master from the original data
//   dc.document_template_master = orig.document_template_master;

//   // Return the processed DocumentTemplate object
//   return dc;
// }

// export async function apiGetDocumentTemplate(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplateId: number
// ) {
//   try {
//     const type="decryption"
//     // Fetch document template data from the API
//     // const response = await client.get(
//     //   `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateId}`
//     //   , {},type
//     // );

//     const response = await getEncrypted(
//       `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateId}`
//     )

//     console.log("🚀 ~ response:", response.data)
//     // Extract the original data from the API response

//     // Rest of the function code goes here...

//     // Return the processed DocumentTemplate object
//     // return dc;
//   } catch (error) {
//     // Handle the error appropriately, you can log it or throw a custom error
//     console.error("Error fetching document template:", error);
//     throw new Error("Failed to fetch document template. Please try again later.");
//   }
// }

// original....no need to do any thing ... before multiple
export async function apiGetDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateId: number
) {
  // // // Fetch document template data from the API

  // const response = await client.get(
  //   `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateId}`
  // );

  const response = await getEncrypted(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateId}`
  );

  const orig = response.data;
  // Function to parse import text into an array of objects
  const parseImportText = (importText: string) =>
    importText.split("\n").map((line) => {
      const [partType, partText] = line.split(";");
      return { part_type: partType, part_text: partText };
    });

  // Function to create a new section object with necessary properties
  const createSection = (section: any, index: number) => ({
    ...section,
    subsections: [],
    component_order: orig.document_section_orders[index],
    pending_addition: orig.document_section_additions[index],
  });

  // Function to create a new subsection object with necessary properties
  const createSubsection = (subsection: any, index: number) => ({
    ...subsection,
    elements: [],
    component_order: orig.document_sub_section_orders[index],
    pending_addition: orig.document_sub_section_additions[index],
    pending_deleted_at:
      orig.deleted_sub_sections &&
      orig.deleted_sub_sections.includes(subsection.id)
        ? "soft-deleted"
        : subsection.pending_deleted_at,
  });

  // Create a new DocumentTemplate object with processed data
  const dc: DocumentTemplate = {
    ...orig.document_template,
    sections: orig.document_sections.map(createSection),
    variables: orig.document_variables,
    included_subSections: orig.sections_sub_section,
    included_section: orig.included_sections,
    sections_sub_section: orig.sections_sub_section,
    is_version_show: orig.is_version_show,
    imported_sections: orig.imported_sections,
    imported_sub_sections: orig.imported_sub_sections,
    is_undo_show: orig.is_undo_show,
    references: orig.document_references,
    is_version_available: orig.is_version_available,
    element_version: orig.document_elements_versions,
    deleted_sub_sections: orig.deleted_sub_sections,
    import_text: parseImportText(orig.import_text),
  };

  // Initialize dictionaries to store sections and subsections for efficient assignment
  const sectionDict: Record<number, DocumentSection> = {};
  const subsectionDict: Record<number, DocumentSubsection> = {};

  // Populate the section dictionary
  dc.sections?.forEach((section, index) => {
    sectionDict[section.id] = section;
  });

  // Populate the subsection dictionary and associate subsections with their parent sections
  orig.document_sub_sections?.forEach((subsection: any, index: number) => {
    const parentSectionId = orig.document_sub_section_parents[index];
    const parentSection = sectionDict[parentSectionId];
    if (parentSection) {
      const newSubsection = createSubsection(subsection, index);
      parentSection.subsections!.push(newSubsection);
      subsectionDict[subsection.id] = newSubsection;
    }
  });
  // Populate the document elements within their respective subsections
  orig.document_elements?.forEach((documentElement: any) => {
    const subsection = subsectionDict[documentElement.document_sub_section_id];
    if (subsection) {
      subsection.elements!.push({
        ...documentElement,
        pending_addition: subsection.pending_addition,
        difference_content:
          orig.difference_content && orig.difference_content[subsection.id]
            ? orig.difference_content[subsection.id]
            : documentElement.difference_content,
        heading_level: orig.heading_levels[0][subsection.id] || 2, // Access heading level
      });
    }
  });
  // Sort sections and subsections by component order
  dc.sections?.sort(compareComponentOrder);
  dc.sections?.forEach((section) =>
    section.subsections?.sort(compareComponentOrder)
  );
  // Assign the document template master from the original data
  dc.document_template_master = orig.document_template_master;

  // console.log("response document Template" , dc)
  // Return the processed DocumentTemplate object
  return dc;
}

// Optimization done
// export async function apiGetDocumentTemplate(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplateId: number
// ) {
//   try {
//     // Fetch document template data from the API concurrently
//     const [ orig] = await Promise.all([

//       client.get(`/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateId}`),
//     ]);

//     // Extract the original data from the API responses
//     // const { data: response_data } = response;
//     const { data: orig_data } = orig;

//     // Define function to parse import text into an array of objects
//     const parseImportText = (importText: string) =>
//       importText.split("\n").map((line) => {
//         const [partType, partText] = line.split(";");
//         return { part_type: partType, part_text: partText };
//       });

//     // Define function to create a new section object with necessary properties
//     const createSection = (section: any, index: number) => ({
//       ...section,
//       subsections: [],
//       component_order: orig_data.document_section_orders[index],
//       pending_addition: orig_data.document_section_additions[index],
//     });

//     // Define function to create a new subsection object with necessary properties
//     const createSubsection = (subsection: any, index: number) => ({
//       ...subsection,
//       elements: [],
//       component_order: orig_data.document_sub_section_orders[index],
//       pending_addition: orig_data.document_sub_section_additions[index],
//       pending_deleted_at:
//         orig_data.deleted_sub_sections &&
//         orig_data.deleted_sub_sections.includes(subsection.id)
//           ? "soft-deleted"
//           : subsection.pending_deleted_at,
//     });

//     // Create a new DocumentTemplate object with processed data
//     const dc: DocumentTemplate = {
//       ...orig_data.document_template,
//       sections: orig_data.document_sections.map(createSection),
//       variables: orig_data.document_variables,
//       included_subSections: orig_data.sections_sub_section,
//       included_section: orig_data.included_sections,
//       sections_sub_section: orig_data.sections_sub_section,
//       is_version_show: orig_data.is_version_show,
//       imported_sections: orig_data.imported_sections,
//       imported_sub_sections: orig_data.imported_sub_sections,
//       is_undo_show: orig_data.is_undo_show,
//       references: orig_data.document_references,
//       is_version_available: orig_data.is_version_available,
//       element_version: orig_data.document_elements_versions,
//       deleted_sub_sections: orig_data.deleted_sub_sections,
//       import_text: parseImportText(orig_data.import_text),
//     };

//     // Initialize dictionaries to store sections and subsections for efficient assignment
//     const sectionDict: Record<number, DocumentSection> = {};
//     const subsectionDict: Record<number, DocumentSubsection> = {};

//     // Populate the section dictionary concurrently
//     const populateSectionDictPromises = dc.sections?.map((section, index) => {
//       sectionDict[section.id] = section;
//     });

//     // Populate the subsection dictionary and associate subsections with their parent sections concurrently
//     const populateSubsectionDictPromises = orig_data.document_sub_sections?.map(async (subsection: any, index: number) => {
//       const parentSectionId = orig_data.document_sub_section_parents[index];
//       const parentSection = sectionDict[parentSectionId];

//       if (parentSection) {
//         const newSubsection = createSubsection(subsection, index);
//         parentSection.subsections!.push(newSubsection);
//         subsectionDict[subsection.id] = newSubsection;
//       }
//     });

//     // Await all concurrent dictionary population tasks
//     await Promise.all([...(populateSectionDictPromises || []), ...(populateSubsectionDictPromises || [])]);

//     // Populate the document elements within their respective subsections concurrently
//     const populateDocumentElementsPromises = orig_data.document_elements?.map(async (documentElement: any) => {
//       const subsection = subsectionDict[documentElement.document_sub_section_id];
//       if (subsection) {
//         subsection.elements!.push({
//           ...documentElement,
//           pending_addition: subsection.pending_addition,
//         });
//       }
//     });

//     // Await all concurrent document elements population tasks
//     await Promise.all(populateDocumentElementsPromises || []);

//     // Sort sections and subsections by component order
//     dc.sections?.sort(compareComponentOrder);
//     dc.sections?.forEach((section) => section.subsections?.sort(compareComponentOrder));

//     // Assign the document template master from the original data
//     dc.document_template_master = orig_data.document_template_master;

//     // Return the processed DocumentTemplate object
//     return dc;
//   } catch (error) {
//     // Handle errors gracefully
//     console.error("Error fetching document template:", error);
//     throw error;
//   }
// }

export function apiDeleteDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToDelete: DocumentTemplate
) {
  return client.delete(
    `/organizations/${organizationId}/document_templates/${documentTemplateToDelete.id}`
  );
}

export function apiDeleteDocumentRecord(
  organizationId: number,
  workspaceId: number,
  RecordToDelete: DocumentRecord
) {
  return client.delete(`/document_records/${RecordToDelete.id}/delete`);
}

export function apiUnDeleteDocumentRecord(
  organizationId: number,
  workspaceId: number,
  RecordToUnDelete: DocumentRecord
) {
  return client.get(`/document_records/${RecordToUnDelete.id}/undelete`);
}

export function apiCloneDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToClone: DocumentTemplate,
  targetWorkspaceId: number
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplateToClone.id}/clone_to_workspace/${targetWorkspaceId}`
  );
}

export function apiMoveDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToMove: DocumentTemplate,
  targetWorkspaceId: number
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplateToMove.id}/move_to_workspace/${targetWorkspaceId}`
  );
}

export function apiMakeNormalDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToMakeNormal: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplateToMakeNormal.id}/make_normal`
  );
}

export function apiDraftCollaborationDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documnetTemplate: DocumentTemplate,
  documentStatus: string
) {
  // [post("/organizations/<organization_id>/workspaces/<workspace_id>/document_templates/<_document_template_id>/draft_collaboration" ,format = "json", data = "<payload>")]

  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documnetTemplate.id}/draft_collaboration`,
    {
      status: documentStatus,
    }
  );
}

export function apiUndeleteDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToDelete: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplateToDelete.id}/undelete`
  );
}

export function apiUpdateImportDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  dtID: number,
  dtName: string,
  dtCategory: string,
  dtSubCategory: string,
  dtType: boolean
) {
  return client.put(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${dtID}//import_update`,
    {
      name: dtName,
      category: dtCategory,
      sub_category: dtSubCategory,
      is_once_off: dtType,
    }
  );
}

export function apiUpdateDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToUpdate: DocumentTemplate
) {
  return client.put(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateToUpdate.id}`,
    documentTemplateToUpdate
  );
}

export function apiUpdateDocumentTemplateImportText(
  organizationId: number,
  workspaceId: number,
  documentTemplateId: number,
  data: string
) {
  return client.put(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateId}/update_import`,
    { import_text: data }
  );
}

export function apiExecuteDocumentTemplateImport(
  organizationId: number,
  workspaceId: number,
  documentTemplateToUpdate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateToUpdate.id}/conclude_import`
  );
}

export function apiExecuteImpactAnalysis(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  imactAnaysisObject: any
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplate.id}/approve_reject`,
    { approved_stuff: imactAnaysisObject }
  );
}

export function apiGetImpact(
  organizationId: number,
  workspaceId: number,
  documentTemplateToPublish: DocumentTemplate,
  documentSectionsID?: any
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateToPublish.id}/impact`
  );
}

export function apiPublishDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToPublish: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateToPublish.id}/publish`
  );
}

export function apiAgainPublishDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToPublish: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateToPublish.id}/publish_again`
  );
}

export function apiSubmitForApprovalDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToPublish: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateToPublish.id}/send_for_approvals`
  );
}

export function apiApprovalPublishDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  documentTemplateToPublish: DocumentTemplate,
  payload: ApprovalPayload
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplateToPublish.id}/publish`,
    payload
  );
}

export function apiCreateDocumentRecord(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  documentName: string,
  variableValues: Variable[],
  returnWholeError?: boolean
) {
  const payload = {
    name: documentName,
    variable_values: JSON.stringify([
      variableValues.map((v) => {
        return {
          // id: v.id,
          // variable_names: v.variable_name,
          variable_name: v.variable_name,
          value: v.default_value,
        };
      }),
    ]),
  };

  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplate.id}/document_records`,
    payload,
    {},
    true
  );
}

export function apiCreateBulkDocumentRecord(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  bulkDocuments: any
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplate.id}/bulk_document_generation`,
    { document_records: bulkDocuments }
  );
}

// export function apiCreateBulkDocumentRecord(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplate: DocumentTemplate,
//   bulkDocuments: any
// ) {
//   return client
//     .post(
//       `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplate.id}/bulk_document_generation`,
//       { document_records: bulkDocuments }
//     )
//     .then((response) => {
//       return response.data; // Assuming the response contains a 'data' property with the result
//     })
//     .catch((error) => {
//       throw error; // Handle or rethrow the error as needed
//     });
// }

// export async function apiCreateBulkDocumentRecord(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplate: DocumentTemplate,
//   bulkDocuments: any
// ) {
//   const response = await axios.post(
//     `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplate.id}/bulk_document_generation`,
//     { document_records: bulkDocuments },
//     { timeout: 600000 } // Set a 60-second timeout
//   );

//   console.log("the response" , response)
//   return response.data; // Assuming the response contains a 'data' property with the result
//   }

export function apiGetDocumentNameSuggestion(
  documentTemplate: DocumentTemplate,
  workspaceID: number
) {
  return client.get(
    `/workspace/${workspaceID}/document_templates/${documentTemplate.id}/document_records/suggest_name`
  );
}

export function apiDocumentRecordPreview(
  organizationId: number,
  workspaceId: number,
  documentRecord: DocumentRecord
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_records/${documentRecord.id}/generate`,
    { isBinary: true }
  );
}

function readRecordFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export async function apiGetDynamicTables(
  organizationId: number,
  workspacesId: number,
  documentTemplateId: number
) {
  try {
    const response = await client.get(
      `/organizations/${organizationId}/document_templates/${documentTemplateId}/document_elements/table_elements`
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving Admins of Current Organization:", error);
  }
}

///error here
export function apiGetDocumentTemplateImagePreview(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplate.id}/preview.png`,
    { isBinary: true }
  );
}

export function apiGetVariablesWithRecord(record_ID: number) {
  return client.get(`/document_record/${record_ID}`);
}

export function apiGetDocumentRecordImagePreview(
  organizationId: number,
  workspaceId: number,
  documentRecord: DocumentRecord
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_records/${documentRecord.id}/preview.png`,
    { isBinary: true }
  );
}

export function apiGetVariables(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_variables`
  );
}

export function apiGetReferences(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_references`
  );
}

export function apiCreateVariable(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  variable: VariableCreateRequest
) {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_variables`,
    variable
  );
}

export function apiCreateReference(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  reference: ReferenceCreateRequest
) {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_references`,
    reference
  );
}

export function apiUpdateVariable(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  variable: Variable
) {
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_variables/${variable.id}`,
    variable
  );
}

export function apiAttachVersionDocumentElement(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sectionID: number,
  subSectionID: number,
  documentElementID: number
) {
  // [put("/organizations/<_organization_id_>/document_templates/<_document_template_id>/document_sections/<document_section_id>/document_sub_sections/<document_sub_section_id>/attach_element", format = "json" , data = "<attach_element_id>")]
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${sectionID}/document_sub_sections/${subSectionID}/attach_element`,
    { element_id: documentElementID }
  );
}

export function apiUpdateReference(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  reference: Reference
) {
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_references/${reference.id}`,
    reference
  );
}

export function apiDeleteVariable(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  variable: Variable
) {
  return client.delete(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_variables/${variable.id}`
  );
}

export function apiDeleteReference(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  reference: Reference
) {
  return client.delete(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_references/${reference.id}`
  );
}

export function apiCreateSectionWithElement(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sectionHeading: string,
  sectionComponentOrder: number,
  element: DocumentElement,
  sectionsToUpdate?: any
) {
  // Add the version
  const payload = {
    versioned: false,
    component_order: sectionComponentOrder, //!Section
    heading: "notused", //!subsection
    Sec_heading: sectionHeading, //!section
    element_versioned: false, //!element
    content: element.content, //!element
    content_format: element.content_format, //!element
    content_type: element.content_type, //!element
    content_order: 0, //!element
    heading_level: 2, //!element
    Sec_heading_level: 1, //!section
    Sec_content_format: "Toclf", //! Section   //! must be defined here it is accepting String values ""
    SectionsToUpdate: sectionsToUpdate,
  };
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/create_section_subsection_element`,
    payload
  );
}

export function apiCreateSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSectionCreateRequest,
  uniqueID?: number,
  documentMasterFormat?: any,
  sectionsToUpdate?: any
) {
  // Add the version
  const payload = {
    ...section,
    versioned: documentTemplate.published_date !== null,
    uniqueID: uniqueID,
    sectionsToUpdate: sectionsToUpdate,
    section_formatting: documentMasterFormat,
  };
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections`,
    payload
  );
}

export function apiUpdateSectionsComponentOrders(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  SectionsToUpdate: any
) {
  const payload = {
    SectionsToUpdate: SectionsToUpdate,
  };
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/section_component_orders`,
    payload
  );
}

export function apiCreateTitleSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: TitleSectionCreateRequest
) {
  // Add the version
  const payload = {
    ...section,
  };
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections`,
    payload
  );
}

export function apiAttachSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sourceDocumentSectionId: number,
  componentOrder: number,
  sourceDocumentId?: number,
  SectionswithSubSections?: any,
  SectionComponentOrder?: any
) {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/attach_document_section/${sourceDocumentSectionId}/at/${componentOrder}/source_template_id/${sourceDocumentId}`,
    {
      SectionswithSubSections: SectionswithSubSections,
      SectonswithComponentOrder: SectionComponentOrder,
    }
  );
}

export function apiAttachCopySection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sourceDocumentSectionId: number,
  componentOrder: number,
  sourceDocumentId?: number,
  SectionswithSubSections?: any,
  SectionComponentOrder?: any
) {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/attach_document_section/${sourceDocumentSectionId}/at/${componentOrder}/source_template_id/${sourceDocumentId}/copy_section_with_subsections`,
    {
      SectionswithSubSections: SectionswithSubSections,
      SectonswithComponentOrder: SectionComponentOrder,
    }
  );
}

export function apiAttachSubSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sourceDocumentSectionId: number,
  componentOrder: number,
  sourceDocumentId?: number,
  subSectionsId?: any,
  destinationSectionId?: any,
  subSectionsComponentOrder?: any
) {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/at/${componentOrder}/source_template_id/${sourceDocumentId}/destinationparentsection/${destinationSectionId}`,
    { subSectionsId, subSectionsComponentOrder: subSectionsComponentOrder }
  );
}

export function apiAttachCopySubSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sourceDocumentSectionId: number,
  componentOrder: number,
  sourceDocumentId?: number,
  subSectionsId?: any,
  destinationSectionId?: any,
  subSectionsComponentOrder?: any
) {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/at/${componentOrder}/source_template_id/${sourceDocumentId}/destinationparentsection/${destinationSectionId}/copy_subsections`,
    { subSectionsId, subSectionsComponentOrder: subSectionsComponentOrder }
  );
}

// export function apiAttachSection(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplate: DocumentTemplate,
//   sourceDocumentSectionId: number,
//   componentOrder: number,
//   sourceDocumentId?: number,
//   subSectionsId?:any,
//   destinationSectionId?:any
// ) {
//   return client.post(
//     `/organizations/${organizationId}/document_templates/${documentTemplate.id}/attach_document_section/${sourceDocumentSectionId}/at/${componentOrder}/source_template_id/${sourceDocumentId}/destinationparentsection/${destinationSectionId}`, {subSectionsId}
//   );
// }

// export function apiAttachSection(
//   organizationId: number,
//   workspaceId: number,
//   documentTemplate: DocumentTemplate,
//   sourceDocumentSectionId: number,
//   componentOrder: number
// ) {
//   return client.post(
//     `/organizations/${organizationId}/document_templates/${documentTemplate.id}/attach_document_section/${sourceDocumentSectionId}/at/${componentOrder}`,
//     {}
//   );
// }

export function apiGetSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sectionId: number
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${sectionId}`
  );
}

export function apiUpdateSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection
) {
  //Make sure the section is just the PUT fields
  const sectionPayload = {
    id: section.id,
    heading: section.heading,
    component_order: section.component_order,
    versioned: false, //If it's edit, it's versioned
    heading_level: section.heading_level,
    formatting: section.formatting,
    section_formatting: "",
  };
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}`,
    sectionPayload
  );
}

export function apiUpdateSectionContent(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection
) {
  //Make sure the section is just the PUT fields
  const sectionPayload = {
    id: section.id,
    heading: section.heading,
    component_order: section.component_order,
    versioned: false, //If it's edit, it's versioned
    heading_level: section.heading_level,
    formatting: section.formatting,
    section_formatting: "",
  };
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/update_section`,
    sectionPayload
  );
}

export function apiDetachSectionFromCurrentDoc(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sectionToDelete: DocumentSection
) {
  // Choosing which delete to use.
  const versionedUnversionedSuffix = documentTemplate.published_date
    ? //  sectionToDelete.edit
      "delete_versioned"
    : "delete_unversioned";

  return client.delete(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${sectionToDelete.id}/delete_section`
  );
}

export function apiDeleteSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sectionToDelete: DocumentSection
) {
  // Choosing which delete to use.
  const versionedUnversionedSuffix =
    sectionToDelete.heading == TABLE_OF_CONTENTS_SECTION ||
    sectionToDelete.heading == LIST_OF_FIGURES ||
    sectionToDelete.heading == LIST_OF_TABLES
      ? "delete_unversioned"
      : //  sectionToDelete.edit
      //documentTemplate.published_date
      // dated : 3/1/2024 final version
      sectionToDelete.publication_no && sectionToDelete.publication_no != null
      ? "delete_versioned"
      : "delete_unversioned";

  return client.delete(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${sectionToDelete.id}/${versionedUnversionedSuffix}`
  );
}

export function apiUndoDeletedSection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sectionToUndoDelete: DocumentSection
) {
  // Choosing which section to undo.
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/section/${sectionToUndoDelete.id}/undo_section`
  );
}

export function apiCreateSubsection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection
) {
  const payload = { ...subsection, versioned: false };
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/document_sub_sections`,
    payload
  );
}

export function apiUpdateSubsectionsComponentOrder(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subSectionsToUpdateID: any
) {
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/update_orders`,
    {
      orderings: subSectionsToUpdateID,
    }
  );
}

export function apiUpdateSubsection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection
) {
  const payload = {
    heading: subsection.heading,
    component_order: subsection.component_order,
    // versioned: section.edit,
    versioned: false,
  };
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/document_sub_sections/${subsection.id}`,
    payload
  );
}

export function apiDeleteSubsection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsectionToDelete: DocumentSubsection
) {
  // Choosing which delete to use.
  const versionedUnversionedSuffix =
    subsectionToDelete.elements &&
    subsectionToDelete.elements[0] &&
    (subsectionToDelete.elements[0].content_type === ContentType.Spacer ||
      subsectionToDelete.elements[0].content_type === ContentType.PageBreak)
      ? "delete_unversioned"
      : // dated : 3/1/2024
      subsectionToDelete.elements &&
        subsectionToDelete.elements[0] &&
        subsectionToDelete.elements[0].publication_no != null
      ? //  documentTemplate.published_date
        // section.edit
        "delete_versioned"
      : "delete_unversioned";

  return client.delete(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/document_sub_sections/${subsectionToDelete.id}/${versionedUnversionedSuffix}`
  );
}

export function apiDetachSubsection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsectionToDelete: DocumentSubsection
) {
  return client.delete(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_section/${section.id}/document_sub_section/${subsectionToDelete.id}/delete_sub_section`
  );
}

export function apiUndoDeletedSubsection(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsectionToDeleteUndo: DocumentSubsection
) {
  // Choosing which delete to use.

  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sub_section/${subsectionToDeleteUndo.id}/undo_sub_section`
  );
}

// export function apiDeleteSubsection(
//   organizationId: number,
//   workspaceId: number,
//   userId:any,
//   documentTemplate: DocumentTemplate,
//   section: DocumentSection,
//   subsectionToDelete: DocumentSubsection,
// ) {
//   // Choosing which delete to use.
//   const versionedUnversionedSuffix = section.edit
//     ? "delete_versioned"
//     : "delete_unversioned";

//   return client.delete(
//     `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/document_sub_sections/${subsectionToDelete.id}/user_id/ ${userId} /${versionedUnversionedSuffix}`
//   );
// }

export async function apiCreateSubsectionWithElement(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection,
  documentElement: DocumentElement | any,
  uniqueID: number,
  subSectionsToUpdate: any,
  subSectionsToUpdateID: any,
  parentSubHeading?: number
) {
  const payload = {
    versioned: section.edit,
    component_order: subsection.component_order,
    heading: subsection.heading,
    element_versioned: false, // section.edit,
    content: documentElement.content,
    content_format: documentElement.content_format,
    content_type: documentElement.content_type,
    content_order: 0,
    heading_level: documentElement.heading_level as number,
    uniqueID: uniqueID,
    subSectionsToUpdate: subSectionsToUpdate,
    subSectionsToUpdateID: subSectionsToUpdateID,
    formatting:
      documentElement.content_type === "TABLE"
        ? documentElement.formatting
        : null,
    above_heading:
      documentElement.content_type === "TEXT" && parentSubHeading != 0
        ? parentSubHeading
        : null,
  };
  return await client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/document_sub_sections_with_element`,
    payload
  );
}

export function apiCreateDocumentElement(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection,
  documentElement: DocumentElement
) {
  const payload = { ...documentElement, versioned: section.edit };
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/document_sub_sections/${subsection.id}/document_elements`,
    payload
  );
}

// this one is callig in updating  the variable
export function apiUpdateDocumentElement(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection,
  documentElement: DocumentElement | any
  // isTextEmpty?:boolean
) {
  // Create the minimal payload.
  let uodatedFormattingString: any;

  if (documentElement?.content_type === "TABLE") {
    const parseDocElement = JSON?.parse(documentElement?.formatting);

    let totalWidth = 0;

    //calculating total width for the first row since width will be same for all column cells
    const data = JSON.parse(documentElement?.formatting);
    data[0]?.forEach((value: any) => {
      totalWidth += parseInt(value?.style?.editorColumnWidth);
    });

    for (let rows = 0; rows < data?.length; rows++) {
      parseDocElement[rows]?.map((item: any) => {
        item.style.editorTotalWidth = totalWidth;
        return item;
      });
    }
    uodatedFormattingString = JSON?.stringify(parseDocElement);
  }

  const payload = {
    content: documentElement.content,
    content_format: documentElement.content_format,
    content_type: documentElement.content_type,
    content_order: documentElement.content_order,
    //  is_changed: isTextEmpty,
    // versioned: section.edit,
    element_versioned: documentElement.publication_no !== null ? true : false,
    heading_level: parseInt(documentElement.heading_level + ""),
    formatting:
      documentElement.formatting === "{}"
        ? null
        : documentElement?.content_type === "TABLE"
        ? uodatedFormattingString
        : documentElement?.formatting,
  };
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/document_sub_sections/${subsection.id}/document_elements/${documentElement.id}`,
    payload
  );
}

// this one is callig in updating  the variable
export function apiUpdateDocumentElementContent(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection,
  documentElement: DocumentElement | any
) {
  // Create the minimal payload.
  let uodatedFormattingString: any;

  if (documentElement?.content_type === "TABLE") {
    const parseDocElement = JSON?.parse(documentElement?.formatting);

    let totalWidth = 0;

    //calculating total width for the first row since width will be same for all column cells
    const data = JSON.parse(documentElement?.formatting);
    data[0]?.forEach((value: any) => {
      totalWidth += parseInt(value?.style?.editorColumnWidth);
    });

    for (let rows = 0; rows < data?.length; rows++) {
      parseDocElement[rows]?.map((item: any) => {
        item.style.editorTotalWidth = totalWidth;
        return item;
      });
    }
    uodatedFormattingString = JSON?.stringify(parseDocElement);
  }

  const payload = {
    content: documentElement.content,
    content_format: documentElement.content_format,
    content_type: documentElement.content_type,
    content_order: documentElement.content_order,
    // versioned: section.edit,
    // element_versioned: section.edit,
    element_versioned: documentElement.publication_no !== null ? true : false,
    heading_level: parseInt(documentElement.heading_level + ""),
    formatting:
      documentElement.formatting === "{}"
        ? null
        : documentElement?.content_type === "TABLE"
        ? uodatedFormattingString
        : documentElement?.formatting,
  };

  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections/${section.id}/document_sub_sections/${subsection.id}/document_elements/${documentElement.id}/update_content_format`,
    payload
  );
}

("/organizations/<organization_id_>/document_templates/<document_template_id>/document_elements/heading_levels");

export function apiUpdateDocumentElementHeadingLevel(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subSectionsLevelsToUpdate: any
) {
  // Create the minimal payload.
  const payload = {
    headinglevelsToUpdate: subSectionsLevelsToUpdate,
  };

  // #[put("/organizations/<organization_id_>/document_templates/<document_template_id>/document_section/<_document_section_id>/document_elements/heading_levels_ss", format="json", data = "<heading_level_update>")]

  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_section/${section.id}/document_elements/heading_levels_ss`,
    payload
  );

  // return client.put(
  //   `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_elements/heading_levels`,
  //   payload
  // );
}

export function apiGetDocumentPreview(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplate.id}/generate`,
    { isBinary: true }
  );
}

export function apiGenerateDocumentPreview(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplate.id}/generate`,
    { isBinary: true }
  );
}

function readFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export async function apiUploadDocumentElementImage(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection,
  documentElement: DocumentElement,
  file: File
) {
  // Temporary construction of the URL until client is refactored.
  const url = `${host}/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_images_png`;
  let headers = {};
  // Content-Type: image/png
  const token = getLocalUserToken();
  if (token) {
    headers = {
      Authorization: token,
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }
  const contentBuffer = await readFileAsync(file);
  const response = await window.fetch(url, {
    method: "POST",
    body: contentBuffer as BodyInit,
    headers: headers,
  });
  const data = await response.json();
  if (response.ok) {
    // Return a result object similar to Axios
    return {
      status: response.status,
      data,
      headers: response.headers,
      url: response.url,
    };
  }
  throw new Error(response.statusText);
}
