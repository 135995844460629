import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  DocumentElement,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../../types/DocumentTemplate";
import { ViewPreferences } from "../../header/preferencesSlice";
import CloseIcon from "@mui/icons-material/Close";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { useState } from "react";
import DocumentElementEditor from "../DocumentElementEditor";
import useActions, { useAppDispatch } from "../../../../app/hooks";
import "./style.css";
import { UTCtoLocalTime } from "../utils";
import { apiGetVersionElements } from "../../../../services/documentTemplatesAPI";
import React from "react";

const style = {
  iconStyle: {
    marginRight: "2px", // You can adjust the margin as needed
    fontSize: "2.0rem",
    color: "#4C33FF", // You can adjust the color as needed
  },
  buttonStyle: {
    margin: "0 10px", // Adjust the margin as needed
    borderRadius: "3px", // Add some border-radius for rounded buttons
    backgroundColor: "primary.main", // Customize the background color
    cursor: "pointer",
    padding: "5px 10px", // Adjust padding as needed
    width: "80px",
  },
};

export default function VersionElementSelector(props: {
  documentSectionSummary: DocumentSectionSummary;
  documentTemplateSummary: DocumentTemplate;
  parentSubsection: DocumentSubsection;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  closeDialogHandler: any;
  currentSelectedElement: number;
}) {
  const actions = useActions();
  const [selectedElement, setSelectedElement] = useState<number | null>(null);
  const subsection = props.parentSubsection;
  const versionedElement = props.documentTemplateSummary.element_version;
  const [elementCopy, setElementsCopy] = useState<any[]>([]);
  const [requestStatus, setRequestStatus] = useState("idle");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setRequestStatus("loading");
        const response = await apiGetVersionElements(
          props.organizationId,
          props.workspaceId,
          props.documentTemplateSummary.id,
          props.documentSectionSummary.id,
          props.parentSubsection.id
        );
        const sortedElements = response;
        try {
          sortedElements.sort((a: any, b: any) => {
            if (a.created_at && b.created_at) {
              return b.created_at.localeCompare(a.created_at); // Compare in reverse order for descending
            } else if (!a.created_at && !b.created_at) {
              return 0; // Both objects are missing created_at, so their order doesn't matter
            } else if (!a.created_at) {
              return 1; // b comes before a since a doesn't have created_at
            } else {
              return -1; // a comes before b since b doesn't have created_at
            }
          });
        } catch (e) {
          console.log(e);
        }
        setElementsCopy(sortedElements);
        setRequestStatus("idle");
      } catch (error) {
        setElementsCopy([]);
        setRequestStatus("failed");
        console.error(
          "An error occurred while fetching Versioned Elements:",
          error
        );
      }
    };
    fetchData();
  }, []);

  const elementsCopy =
    versionedElement?.filter((element) => {
      return element.document_sub_section_id === subsection.id;
    }) || [];

  try {
    elementsCopy.sort((a, b) => {
      if (a.created_at && b.created_at) {
        return b.created_at.localeCompare(a.created_at); // Compare in reverse order for descending
      } else if (!a.created_at && !b.created_at) {
        return 0; // Both objects are missing created_at, so their order doesn't matter
      } else if (!a.created_at) {
        return 1; // b comes before a since a doesn't have created_at
      } else {
        return -1; // a comes before b since b doesn't have created_at
      }
    });
  } catch (e) {
    console.log(e);
  }

  const handleClose = () => {
    props.closeDialogHandler();
  };

  const handleSave = () => {
    if (selectedElement != null) {
      actions.attachVersionDocumentElement({
        sectionID: props.documentSectionSummary.id,
        subSectionID: props.parentSubsection.id,
        documentElementID: selectedElement,
      });
    }
    props.closeDialogHandler();
  };

  const handleElementSelect = (elementID: number) => {
    if (selectedElement === elementID) {
      setSelectedElement(null);
    } else if (props.currentSelectedElement === elementID) {
      //
    } else {
      setSelectedElement(elementID);
    }
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        className="customModalStyle"
        sx={{ display: "flex", alignItems: "left", justifyContent: "center" }}
      >
        <Paper
          className="paperStyle"
          style={{ maxHeight: "90vh", overflowY: "auto" }}
        >
          <div className="headerStyle">
            <FastRewindIcon style={style.iconStyle} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Version Control
            </Typography>
            <IconButton
              disableRipple
              className="closeButtonStyle"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>

          {requestStatus == "idle" ? (
            <div>
              {(elementCopy || []).map((element, index) => (
                <>
                  <Box
                    sx={{
                      mb: 1,
                      borderRadius: 0.8,
                      padding: 0.5,
                      border: 1,
                      transition: "box-shadow 0.3s ease",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        boxShadow:
                          element.id !== props.currentSelectedElement
                            ? "0px 0px 12px rgba(0, 0, 0, 0.7) "
                            : "default", // Add an elevation effect on hover
                      },
                      cursor:
                        element.id === props.currentSelectedElement
                          ? "not-allowed"
                          : "pointer",
                    }}
                    border={2}
                    borderColor={
                      element.publication_no ? "silver.main" : "green"
                    }
                    p={1}
                    onClick={() => {
                      handleElementSelect(element.id);
                    }}
                    style={{
                      backgroundColor:
                        element.id === selectedElement
                          ? "green"
                          : element.id === props.currentSelectedElement
                          ? "grey"
                          : "white",
                    }}
                  >
                    <div
                      style={{
                        zIndex: 99999999999,
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      <Tooltip title={`Version: ${elementCopy.length - index}`}>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.7em", // Font size in em units
                            marginRight: "10px",
                          }}
                        >
                          Version: {elementCopy.length - index}
                        </span>
                      </Tooltip>
                      <Tooltip
                        title={`Approved by: ${
                          element.approved_by ?? "Pending"
                        }`}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.7em", // Font size in em units
                            marginRight: "10px",
                          }}
                        >
                          Approved by: {element.approved_by ?? "Pending"}
                        </span>
                      </Tooltip>
                      <Tooltip
                        title={`Published by: ${
                          element.published_by ?? "Pending"
                        }`}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.7em", // Font size in em units
                            marginRight: "10px",
                          }}
                        >
                          Published by: {element.published_by ?? "Pending"}
                        </span>
                      </Tooltip>
                      <Tooltip
                        title={`Approved Date: ${
                          UTCtoLocalTime(element.approved_date) ?? "Pending"
                        }`}
                        className="tooltip"
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.7em", // Font size in em units
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Approved Date:{" "}
                          {UTCtoLocalTime(element.approved_date) ?? "Pending"}
                        </span>
                      </Tooltip>
                    </div>

                    <DocumentElementEditor
                      key={subsection.id + "_" + "___" + "_" + element.id}
                      organizationId={props.organizationId!}
                      workspaceId={props.workspaceId!}
                      element={element}
                      parentSubsection={subsection}
                      parentSection={props.documentSectionSummary!}
                      documentTemplate={props.documentTemplateSummary!}
                      isHeading={false}
                      viewPreferences={props.viewPreferences!}
                      forApproval={false}
                      approvalsEnabled={false}
                      approvalOf={-1}
                      readOnly={true}
                    />
                  </Box>
                </>
              ))}
            </div>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "150px",
                width: "100%",
              }}
            >
              {requestStatus == "loading" ? (
                <CircularProgress />
              ) : (
                <Typography fontWeight="bold">
                  Unable to fetch Versioned Elements
                </Typography>
              )}
            </Box>
          )}

          <div className="divButtons" style={{ position: "sticky" }}>
            <Button
              style={style.buttonStyle}
              variant="outlined"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              disabled={selectedElement === null || requestStatus != "idle"}
              style={style.buttonStyle}
              variant="contained"
              onClick={handleSave}
            >
              {"attach"}
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  );
}
