import React, { useState } from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DocumentTemplate, Workspace } from "../../types/DocumentTemplate";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useActions from "../../app/hooks";

export default function DocumentTemplateCloneForm(props: {
  parentCloseHandle: () => void;
  documentTemplate: DocumentTemplate;
  workspaces: Array<Workspace> | null;
  currentWorkspace: Workspace | null;
}) {
  const actions = useActions();

  const [workspaceId, setWorkspaceId] = useState(-1);

  function clonetoWorkspace() {
    /*const newDocumentTemplate = Object.assign({}, props.documentTemplate, {
      name: name,
      purpose: purpose,
      category: category,
    });
    actions.updateDocumentTemplate(newDocumentTemplate);
    props.parentCloseHandle();*/

    const selWorkspaces = props.workspaces!.filter(_ => _.id == workspaceId);

    if (selWorkspaces.length > 0) {
      actions.cloneDocumentTemplate({document_template: props.documentTemplate, target_workspace_id: selWorkspaces[0].id});
      props.parentCloseHandle();
    }
  }

  function handleSelectWorkspace(e: any) {
    setWorkspaceId(e.target.value);
  }

  return (
    <Dialog open={true} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>
        <Typography sx={{marginBottom: "20px"}}>Clone Document Template: {props.documentTemplate.name}</Typography>

        <Typography sx={{marginBottom: "5px"}}>By cloning this document you agree to waivering any liabilities whatsoever against Nomia that my be associated with the document. </Typography>

        <Typography>Select your target workspace</Typography>
      </DialogTitle>
      
      <DialogContent>
        <Box component="form">
        <Select
        sx={{paddingTop:0}}
          value={workspaceId}
          onChange={handleSelectWorkspace}
          fullWidth={true}
        >
          { props.workspaces!.filter(__ => __.id != props.currentWorkspace!.id).map( _ => 
            <MenuItem value={_.id}>{_.name}</MenuItem>
          )}
        </Select>

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={clonetoWorkspace}
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            workspaceId == -1
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
