import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FileUpload, FileUploadProps } from "../../shared/FileUpload";
import useActions from "../../../app/hooks";

/**
 * REF: https://codesandbox.io/s/lgqwn?file=/src/FileUpload/FileUpload.tsx:1869-1877
 *
 * @export
 * @param {{open: boolean}} props
 * @return {*}
 */
export default function ImportTemplateForm(props: { closeDialogHandler: any }) {
  const [file, setFile] = React.useState<File | null>(null);
  const actions = useActions();

  const fileUploadProp: FileUploadProps = {
    accept: "application/pdf",
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        setFile(event.target.files[0]);
       
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
     
      setFile(event.dataTransfer.files[0]);
    },
  };

  const handleUploadTemplate = () => {
    if(file != null)
      actions.importDocumentTemplate(file);
    props.closeDialogHandler();
  };
  
  return (
    <Dialog
      open={true}
      onClose={(e) => {
        props.closeDialogHandler();
      }}
    >
      <DialogTitle>Import Document Template</DialogTitle>
      <DialogContent>
        <FileUpload {...fileUploadProp} />
        {/* <DialogContentText>
        Upload a template PDF h
        </DialogContentText> */}
        {/* <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Email Address"
        type="email"
        fullWidth
        variant="standard"
        />*/}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            props.closeDialogHandler();
          }}
        >
          Cancel
        </Button>
        <Button disabled={file == null} onClick={handleUploadTemplate}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
