import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  Checkbox,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleRounded";
import CancelPresentationSharpIcon from "@mui/icons-material/CancelSharp";
import {
  selectApprovalState,
  selectEditingDocumentTemplate,
  selectIsImpactPending,
  selectSelected,
  setImpactState,
  setImpactSubsection,
  setImpactSubsectionAll,
} from "../../editorSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

export function ApprovalsItems(props: any) {
  const template = useAppSelector(selectEditingDocumentTemplate)
  const selected = useAppSelector(selectSelected);
  const dispatch = useAppDispatch();
  const approvalState = useAppSelector(selectApprovalState);


  const isPendingState = useAppSelector(selectIsImpactPending);
  const [openedSelected, setOpenedSelected] = React.useState<any>(null);
  const [currentSelectedSubsection, setCurrentSelectedSubsection] =
    React.useState<any>(null);

  React.useEffect(() => {
    if (
      selected.documentElement != null &&
      selected.section != null &&
      selected.subSection != null
    ) {
      setOpenedSelected(selected);
      if (
        selected.subSection &&
        Object.prototype.hasOwnProperty.call(
          approvalState,
          selected.subSection.id
        )
      ) {
        const retrievedData = approvalState[selected.subSection.id];
        setCurrentSelectedSubsection(retrievedData);
      } else {
        setCurrentSelectedSubsection(null);
      }
    } else if (
      selected.documentElement == null &&
      selected.section != null &&
      selected.subSection == null
    ) {
      setOpenedSelected(null);
      setCurrentSelectedSubsection(null);
    }
  }, [selected]);

 const isLibraryDeleted = (subsectionID:number)=>{
 return template.deleted_sub_sections.includes(subsectionID)


  }
  React.useEffect(() => {
    try {
      if (
        openedSelected &&
        openedSelected.subSection &&
        Object.prototype.hasOwnProperty.call(
          approvalState,
          openedSelected.subSection.id
        )
      ) {
        const retrievedData = approvalState[openedSelected.subSection.id];
        setCurrentSelectedSubsection(retrievedData);
      } else {
        setCurrentSelectedSubsection(null);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
    if (
      currentSelectedSubsection &&
      isPendingAvailable(currentSelectedSubsection)
    ) {
      handleScrollNextSubSection();
    }
  }, [approvalState]);

  // ?is Anything left to select?
  const isPendingAvailable = (approvalObject: any): boolean => {
    return approvalObject.some((item: any) => item.status === "pending");
  };

  // return object of that subsection
  const returnImpactSubsection = (subsectionID: any) => {
    if (
      subsectionID &&
      Object.prototype.hasOwnProperty.call(approvalState, subsectionID)
    ) {
      const retrievedData = approvalState[subsectionID];
      return retrievedData;
    }
    return null;
  };

  const handleScrollNextSubSection = () => {
    if (openedSelected && openedSelected.subSection) {
      const currentSubsectionId = String(openedSelected.subSection.id); // Convert to string

      const impactObject = returnImpactSubsection(currentSubsectionId);
      const isPending = isPendingAvailable(impactObject);

      if (!isPending) {
        if (isPendingState.isPending && isPendingState.subsectionID) {
          handleScrollNextSubsection(isPendingState.subsectionID);
        }
      } else {
        // No next subsection found (end of the list)
        console.log("No next subsection found.");
      }
    }
  };

  const handleScrollNextSubsection = (subSectionID: any) => {
    const boxElement = document.querySelector(`#subsection-${subSectionID}`);
    // const boxElement = document.querySelector(`#section-${props.parentSection.id}`);
    if (boxElement) {
      // Add a click event listener to the boxElement
      const editorContainer = boxElement.querySelector(
        ".DraftEditor-editorContainer"
      );
      if (editorContainer) {
        if (editorContainer instanceof HTMLElement) {
          editorContainer.click();
          setTimeout(() => {
            boxElement.scrollIntoView({ behavior: "smooth" });
          }, 100); // You can adjust the delay as needed
        } else {
          console.log("Editor not found in the clicked boxElement.");
        }
      } 
      else {
        if ( boxElement instanceof HTMLElement){
          boxElement.click() 
          boxElement.click() 
          setTimeout(() => {
            boxElement.scrollIntoView({ behavior: "smooth" });
          }, 100); // You can adjust the delay as needed
        }
        console.log("Editor container not found in the clicked boxElement.");
      }
    }
  };

  const handleSubsectionChange = (
    sectionId:number,
    subsectionID: number,
    documentID: number,
    value: string,
    isDeleted:boolean
  ) => {
    dispatch(
      setImpactSubsection({
        sectionID:sectionId,
        subsectionID: subsectionID,
        documentID: documentID,
        status: value,
        isDeleted:isDeleted  
      })
    );
  };

  const handleAllChanges = ( sectionId:number,subsection: number, value: string , isDeleted:boolean) => {
    dispatch(
      setImpactSubsectionAll({ sectionID:sectionId, subsectionID: subsection, status: value , isDeleted:isDeleted   })
    );
  };

  const border = "2px solid lightgrey";
  const iconSize = "20px"; // Set the desired fixed size for the icons

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "black",
            paddingTop: "0px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "11vw",
            pl: "15px",
          }}
        >
          Impact
        </Typography>

{/* {
  approvalState.length===0 && (
    <div style={{ fontWeight: "bold", padding: "10px" }}>
    No Impact analysis available for this Document.
  </div>

  )

} */}

        {currentSelectedSubsection && (
          <div style={{marginRight:"1.9vh"}}>
            <Checkbox
              onClick={() => {
                handleAllChanges(  openedSelected.section.id,openedSelected.subSection!.id, "approve",
                // openedSelected.subSection!.pending_deleted_at!==null 
                isLibraryDeleted(openedSelected.subSection!.id)
                
                );
              }}
              icon={
                <CheckCircleOutlineIcon
                  style={{ width: iconSize, height: iconSize }}
                />
              }
              checkedIcon={
                <CheckCircleOutlineIcon
                  style={{ color: "grey", width: iconSize, height: iconSize }}
                />
              }
              inputProps={{ "aria-label": "Impact" }}
            />
            <Checkbox
              onClick={() => {
                handleAllChanges( openedSelected.section.id,openedSelected.subSection!.id, "reject" , 
                
                // openedSelected.subSection!.pending_deleted_at!==null
               
                isLibraryDeleted(openedSelected.subSection!.id)
                );
              }}
              icon={
                <CancelPresentationSharpIcon
                  style={{ width: iconSize, height: iconSize }}
                />
              }
              checkedIcon={
                <CancelPresentationSharpIcon
                  style={{ color: "grey", width: iconSize, height: iconSize }}
                />
              }
              inputProps={{ "aria-label": "Impact" }}
            />
          </div>
        )}
      </Box>
      {currentSelectedSubsection ? (
        currentSelectedSubsection.map((item: any) => (
          <ListItem
            key={`__impact_${item.documentID}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "11vw",
                fontWeight: "bold",
              }}
            >
              {item.documentName}
            </Typography>
            <div>
              <Checkbox
                icon={
                  <CheckCircleOutlineIcon
                    style={{ width: iconSize, height: iconSize }}
                  />
                }
                checkedIcon={
                  <CheckCircleOutlineIcon
                    style={{ width: iconSize, height: iconSize }}
                  />
                }
                checked={item.status === "approve"}
                onClick={() => {
                  handleSubsectionChange(
                    openedSelected.section.id,
                    openedSelected.subSection!.id,
                    item.documentID,
                    "approve",
                    // openedSelected.subSection!==null
                    isLibraryDeleted(openedSelected.subSection!.id)
                  );
                }}
                inputProps={{ "aria-label": "Impact" }}
              />
              <Checkbox
                onClick={() => {
                  handleSubsectionChange(
                    openedSelected.section.id,
                    openedSelected.subSection!.id,
                    item.documentID,
                    "reject",
                    // openedSelected.subSection!==null
                    isLibraryDeleted(openedSelected.subSection!.id)
                  );
                }}
                icon={
                  <CancelPresentationSharpIcon
                    style={{ width: iconSize, height: iconSize }}
                  />
                }
                checkedIcon={
                  <CancelPresentationSharpIcon
                    style={{ width: iconSize, height: iconSize }}
                  />
                }
                checked={item.status === "reject"}
                inputProps={{ "aria-label": "Impact" }}
              />
            </div>
          </ListItem>
        ))
      ) : (
        // Handle the case when currentSelectedSubsection is null
      openedSelected &&  <div style={{ fontWeight: "bold", padding: "10px" }}>
          No Impact analysis available for this content.
        </div>
      )}
    </Box>
  );
}

const sectionHeading = "Impact";

// export const isImpactPending = (state: RootState) => {
//   if(state.document.ImpactState===null) return false ;

//   const impact= state.document.ImpactState

//   for(const tempSubsectionID in impact){
//     if(Object.prototype.hasOwnProperty.call(impact ,tempSubsectionID)){
//       const object=  impact[tempSubsectionID]
//       if(object.some((item:any)=>item.state=="pending")){
//         return {
//           isPending: true,
//           subsectionID:tempSubsectionID,

//         }
//       }
//     }
//   }

//    return {
//     isPending: false,
//     subsectionID:null,
//   }
// };
