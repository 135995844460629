import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import {
  setStatus as setAuthStatus,
  setOrganizationOwner,
  setUser,
  logout,
} from "../../features/auth/authSlice";
import {
  apiChangePassword,
  apiLogin,
  apiPasswordReset,
  apiPasswordResetFinalize,
  apiPasswordResetFinalizeWithUserDetails,
  apiSignup,
  clearRememberMeState,
  getRememberMeState,
  setRememberMeState,
} from "../../services/userAPI";
import { setClearLibraryDocuments } from "src/features/library/librarySlice";
import { setClearLibraryRecords } from "src/features/library/documentRecordsSlice";

/**
 * Login using a username and password, save this to
 * local storage, and fire off a getOrganizations action.
 *
 * @param {ReturnType<typeof actions.login>} action
 * @return {*}
 */
export function* login(action: ReturnType<typeof actions.login>) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  const userPass = action.payload;
  // Get the user and the organization
  try {
    yield put(setClearLibraryDocuments());
    yield put(setClearLibraryRecords());

    const userResponse = yield call(apiLogin, userPass.email, userPass.pass);

    // Set the user.
    yield put(setUser(userResponse.data));

    if (!userResponse.data.first_login) {
      // Get the organizations for this user (which triggers getWorkspaces)
      yield put(actions.getOrganizations());
    }

    if (userPass.rememberMe) {
      setRememberMeState(userPass.rememberMe);
    } else {
      clearRememberMeState();
    }
  } catch (e) {
    if (e == "406" || e == 406) {
      yield put(
        setAuthStatus({
          status: "logged-out",
          error:
            " Your Nomia administrator has removed your user-profile.Unfortunately, you no longer have access to Nomia.",
        })
      );
    } else if (e == 502) {
      yield put(
        setAuthStatus({
          status: "logged-out",
          error:
            "Server encountered a 502 Bad Gateway error. Please try again later.",
        })
      );
    } else {
      yield put(
        setAuthStatus({
          status: "logged-out",
          error: "Unauthorized.Please check your credentials.",
        })
      );
    }
    clearRememberMeState();

    return;
  }
}
export function* signup(action: ReturnType<typeof actions.signup>) {
  yield put(setAuthStatus({ status: "logged-out", error: undefined }));
  // Get the user and the organization
  try {
    const userResponse = yield call(apiSignup, action.payload);

    // Set the user.
    yield put(setAuthStatus({ status: "logged-out", error: undefined }));
  } catch (e) {
    if (e == "406" || e == 406) {
      yield put(
        setAuthStatus({
          status: "logged-out",
          error:
            " Your Nomia administrator has removed your user-profile.Unfortunately, you no longer have access to Nomia.",
        })
      );
    } else if (e == 502) {
      yield put(
        setAuthStatus({
          status: "logged-out",
          error:
            "Server encountered a 502 Bad Gateway error. Please try again later.",
        })
      );
    } else {
      yield put(
        setAuthStatus({
          status: "logged-out",
          error: "Unauthorized.Please check your credentials.",
        })
      );
    }
    clearRememberMeState();

    return;
  }
}

export function* reset(action: ReturnType<typeof actions.reset>) {
  yield put(setAuthStatus({ status: "logged-out", error: undefined }));

  const userPass = action.payload;
  // Get the user and the organization
  try {
    const userResponse = yield call(apiPasswordReset, userPass.email);

    yield put(
      setAuthStatus({ status: "logged-out", error: "Password reset sent" })
    );
  } catch (e) {
    yield put(
      setAuthStatus({
        status: "logged-out",
        error:
          "Could not send password reset mail.  Please contact technical support.",
      })
    );
    return;
  }
}

export function* resetUserPassword(
  action: ReturnType<typeof actions.resetUserPassword>
) {
  // yield put(setAuthStatus({ status: "logged-out", error: undefined }));

  const userPass = action.payload;
  // Get the user and the organization
  try {
    const userResponse = yield call(apiPasswordReset, userPass.email);

    // yield put(setAuthStatus({ status: "logged-out", error: "Password reset sent"}));
  } catch (e) {
    yield put(
      setAuthStatus({
        status: "logged-out",
        error:
          "Could not send password reset mail.  Please contact technical support.",
      })
    );
    return;
  }
}

export function* finalizeReset(
  action: ReturnType<typeof actions.finalizeReset>
) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  const userPass = action.payload;
  // Get the user and the organization
  try {
    const userResponse = yield call(
      apiPasswordResetFinalize,
      userPass.password,
      userPass.token
    );

    (<any>window).location = "/";
  } catch (e) {
    yield put(
      setAuthStatus({
        status: "logged-out",
        error: "Cannot reset password.  Please contact technical support.",
      })
    );
    return;
  }
}

export function* finalizeResetWithUserDetails(
  action: ReturnType<typeof actions.finalizeResetWithUserDetails>
) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  // Get the user and the organization
  try {
    const userResponse = yield call(
      apiPasswordResetFinalizeWithUserDetails,
      action.payload
    );
    yield put(logout());
    yield put(setAuthStatus({ status: "logged-in", error: undefined }));
    (<any>window).location = "/";
  } catch (e) {
    yield put(
      setAuthStatus({
        status: "logged-out",
        error: "Cannot reset password.  Please contact technical support.",
      })
    );
    return;
  }
}

export function* changePassword(
  action: ReturnType<typeof actions.changePassword>
) {
  yield put(setAuthStatus({ status: "loading", error: undefined }));

  const userPass = action.payload;
  // Get the user and the organization
  try {
    // Change password
    const userResponse1 = yield call(apiChangePassword, userPass.password, "");

    // Relogin
    const userResponse2 = yield call(
      apiLogin,
      userPass.email,
      userPass.password
    );

    // Set the user.
    yield put(setUser(userResponse2.data));

    // Get the organizations for this user (which triggers getWorkspaces)
    yield put(actions.getOrganizations());
  } catch (e) {
    yield put(
      setAuthStatus({
        status: "logged-out",
        error: "Cannot reset password.  Please contact technical support.",
      })
    );
    return;
  }
}
