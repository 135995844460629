import {
  Alert,
  Box,
  Card,
 
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { createContext, useContext, useRef, useState } from "react";
import { truncateHeading } from "../../../../services/utils";
import { useEffect } from "react";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplateSummary,
} from "../../../../types/DocumentTemplate";
import useActions, { useAppSelector } from "../../../../app/hooks";
import { ViewPreferences } from "../../header/preferencesSlice";
// import { DocumentElementEditor } from "../DocumentElementEditor";
import { ImageElementEditor } from "../images/ImageElementEditor";
import { getEditorForDocumentElement } from "../utils";
import { selectWorkspaceSummaryState } from "./workspaceSummarySlice";
import { MyObjectContext } from "./LibraryContentForm";
import { inherits } from "util";
// import { LibraryContentEditor } from "./LibraryContentEditor";
/**
 * A single DocumentSectionSummary in the LibraryContent form.
 *
 * @export
 * @param {{
 *   selectElementHandler: any;
 *   documentTemplateSummary: DocumentTemplateSummary
 * }} props
 */

interface MyObject {
  [sectionId: number]: number[];
}

export default function LibraryContentElement(props: {
  selectElementHandler: any;
  documentSectionSummary: DocumentSectionSummary;
  isSelected: boolean;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  documentTemplateSummary: DocumentTemplateSummary;
}) {
  const [Matched, setMatched] = useState<number>(0);
  const section = props.documentSectionSummary;
  const sampleDocumentSubsections = section.sample_document_sub_sections;
  const [searchedIndexes, setSearchedIndexes] = useState<any>([]);
  const sampleDocumentElements = section.sample_document_elements;
  // Store the IDs of Matched Subsection of Search
  const workspaceSummaryState = useAppSelector(selectWorkspaceSummaryState);
  // to maintain the list of Section wih subsection to be added
  const { myObject, setMyObject } = useContext(MyObjectContext);
 
  // select the section
  const selectElementHandler = () => {
    props.selectElementHandler(props.documentSectionSummary);
    // On Section select add all the subsection to be added to the myOBject
    if (!myObject[props.documentSectionSummary.id]) {
      // Get the sample document subsections for the selected section
      const subsections =
        props.documentSectionSummary.sample_document_sub_sections;
      // Add the sectionID and its corresponding subsections to myObject
      const updatedObject = {
        ...myObject,
        [props.documentSectionSummary.id]: subsections.map(
          (subsection) => subsection.id
        ),
      };
      // Update the state with the new object
      setMyObject(updatedObject);
    } else {
      // Remove the existing section ID from myObject
      const {
        [props.documentSectionSummary.id]: removedSection,
        ...remainingObject
      } = myObject;
      // Update the state with the updated object
      setMyObject(remainingObject);
    }
  };

  // To select/deSelect subsections within the Selected Section
  const toggleBoxSelection = (sectionID: number, subsectionID: any) => {
    props.selectElementHandler(props.documentSectionSummary);
    // Create a copy of the existing state using the spread operator
    const updatedObject = { ...myObject };
    // if the sectionID already Exist
    if (updatedObject[sectionID]) {
      const index = updatedObject[sectionID].indexOf(subsectionID);
      // remove subSection if already present in Object
      if (index > -1) {
        updatedObject[sectionID].splice(index, 1);
        // Check if the subsection array is empty, and remove the sectionID if it is
        if (updatedObject[sectionID].length === 0) {
          delete updatedObject[sectionID];
        }
      } else {
        // add the subSectionID to array
        updatedObject[sectionID].push(subsectionID);
      }
    } else {
      updatedObject[sectionID] = [subsectionID];
    }
    // Update the state with the new object
    setMyObject(updatedObject);
  };

  const boxRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (workspaceSummaryState.searchedElement) {
      setSearchedIndexes(workspaceSummaryState.searchedElement);
    }
  }, [workspaceSummaryState]);
  const [firstElemetMatched, setFirstElemetMatched] = useState<number>(0);
  const [focused, setFocused] = useState<boolean>(false);
  const focusedRef = useRef(false);
  const firstElementMatchedRef = useRef(0);

  useEffect(() => {
    loop: for (const section of props.documentTemplateSummary
      .document_section_summaries || []) {
      for (const subSection of section.sample_document_sub_sections) {
        if (searchedIndexes.includes(subSection.id)) {
          setMatched(subSection.id);
          break loop; // Exit the labeled loop
        }
      }
    }
  }, [searchedIndexes]);

  useEffect(() => {
    if (workspaceSummaryState.searchedElement) {
      const targetSubSectionId = Matched;
      const boxElement = document.querySelector(
        `[data-subsection-id="${targetSubSectionId}"]`
      );
      if (boxElement) {
        boxElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [workspaceSummaryState.searchedElement, Matched]);
  return (
    <Box
      style={{
        backgroundColor: myObject
          ? myObject[section.id]
            ? "#c9cad1"
            : "white"
          : "white",
        borderRadius: "0px",
        marginBottom: "8px",
      }}
    >
      <ListItem
        key={section.id}
        button
        selected={props.isSelected}
        onClick={selectElementHandler}
        disableRipple
      >
        <ListItemText
          primary={
            <Typography variant="h4">
              {truncateHeading((section.heading).replace(/## |\+\+|\*|_/g, ''))}
            </Typography>
          }
          secondary={
            <Card>
              <CardContent
                style={{
                  // borderRadius: "0px",
                  backgroundColor: myObject
                    ? myObject[section.id]
                      ? "#c9cad1"
                      : "white"
                    : "white",
                }}
              >
                {sampleDocumentElements.map((element, index) => {
                  const subSectionId = sampleDocumentSubsections[index].id;
                  const isHighlighted =
                    myObject &&
                    myObject[section.id] &&
                    myObject[section.id].includes(subSectionId);
                  return (
                    <Box
                      data-subsection-id={subSectionId} // Add a data attribute with the subSectionId value
                      sx={{
                        borderRadius: 0,
                        padding: 0.5,
                        border: 1,
                        "&:hover": {
                          backgroundColor: "primary.main",
                          opacity: [0.9, 0.8, 0.7],
                        },
                      }}
                      key={subSectionId}
                      border={3}
                      borderColor="black"
                      p={1}
                      onClick={(e) => {
                        e.stopPropagation(); // Stop event propagation
                        toggleBoxSelection(section.id, subSectionId);
                      }}
                      style={{
                        backgroundColor: isHighlighted
                          ? "#4C33FF"
                          : searchedIndexes.includes(subSectionId)
                          ? "#29cfff"
                          : "white",
                        boxShadow: searchedIndexes.includes(subSectionId)
                          ? "0 0 10px rgba(0, 0, 0, 0.3)"
                          : "none",
                        transition:
                          "box-shadow 0.3s ease, background-color 0.3s ease",
                        fontWeight: searchedIndexes.includes(subSectionId)
                          ? "bold"
                          : "inherit",
                        fontStyle: searchedIndexes.includes(subSectionId)
                          ? "italic"
                          : "normal",

                        borderRadius: "0px",
                      }}
                    >
                      {getEditorForDocumentElement(
                        element,
                        sampleDocumentSubsections[index],
                        section,
                        null,
                        props.documentTemplateSummary,
                        props.workspaceId,
                        props.organizationId,
                        props.viewPreferences,
                        false,
                        "sde_" + section.id + "_" + index,
                        false,
                        -1,
                        true
                      )}
                    </Box>
                  );
                })}
              </CardContent>
            </Card>
          }
        ></ListItemText>
      </ListItem>
    </Box>
  );
}
