import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import { Variable } from "../../../types/DocumentTemplate";

export default function VariableTable(
  variables: Variable[],
  isDisable: boolean
) {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{ backgroundColor: "primary.main", color: "#FFFFFF" }}
            >
              <TableCell sx={{ color: "#fff" }}>Variables</TableCell>
              <TableCell align="left" sx={{ color: "#fff" }}>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variables?.map((variable) => (
              <TableRow
                key={variable.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <b>{variable?.variable_name}</b>
                </TableCell>
                <TableCell align="right">
                  <TextField
                    fullWidth
                    value={variable?.default_value}
                    variant="standard"
                    margin="none"
                    disabled={isDisable}
                  ></TextField>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
